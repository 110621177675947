import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd'
import { checkPassword } from '../../utils/stringUtil'
import { updateMemberPwdPro } from '../../service/api'
import { getUsername, getUsertype, loginOut } from '../../utils/auth'

const ChangePwd = (props) => {

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        }
    }

    function handForm(e) {
        if (e.pwd === 'Ctes@0203') {
            return notification.warning({
                message: '请不要使用默认密码!',
            })
        }
        // 新密码提交后直接进入login页面，并且删除所有的保存数据
        let userType = getUsertype()
        let type = null
        if (userType === '个人用户') {
            type = '0'
        } else if (userType === '单位用户') {
            type = '1'
        } else if (userType === '专家用户') {
            type = '2'
        }
        // console.log(e, 11111)
        updateMemberPwdPro({ account: e.account, oldPwd: e.oldPwd, password: e.pwd, type: type }).then(res => {
            // console.log(res, 5555)
            if (res.data.code === 200) {
                notification.success({
                    message: '提示',
                    description: '设置新密码成功，请重新登录！'
                })
                loginOut()
            } else if (res.data.code === 2007) {
                notification.error({
                    message: '提示',
                    description: '旧密码错误，请重新输入！'
                })
            } else {
                return notification.error({
                    message: '提示',
                    description: '系统错误，请重新重置密码！'
                })
            }
        })
    }

    return (
        <Modal title="请设置新密码"
            destroyOnClose
            centered
            visible={props.isModalVisible}
            width={850}
            footer={null}
            style={{ textAlign: 'center' }}
            maskClosable={false}
            keyboard={false}
            closable={false}
        >
            <div style={{ width: "100%" }}>
                <Form name="updatepwd"
                    labelAlign="center"
                    {...layout}
                    initialValues={{ 'account': getUsername() }}
                    onFinish={handForm}
                >
                    <Form.Item name="account"
                        label="账号"
                        rules={[
                            {
                                required: true,
                                message: '请填写账号'
                            }
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="oldPwd"
                        label="旧密码"
                        rules={[
                            {
                                required: true,
                                message: "请输入旧密码!"
                            }
                        ]}
                    >
                        <Input.Password placeholder='请填写旧密码' />
                    </Form.Item>
                    <Form.Item name="pwd"
                        label="登录密码"
                        rules={[
                            {
                                validator: checkPassword
                            },
                            {
                                required: true,
                                message: "请填写登录密码!"
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="请填写登录密码" />
                    </Form.Item>
                    <Form.Item
                        name="confirmPwd"
                        label="确认密码"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "请确认您的密码!"
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('pwd') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('两次密码不一致!')
                                }
                            })
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form>
            </div>
        </Modal>
    )
};

export default ChangePwd;
