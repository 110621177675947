import React from 'react'
import './constitution.scss'

class Constitution extends React.Component {
  render() {
    return (
      <div className="constitution">
        <div className="contentOneHeader"><span>《长江技术经济学会章程》</span></div>
        <div className="contentOneOne">
          <div className="contentOneSubhead"><span>第一章 总 则</span></div>
          <p>
            <span><span className="paragraphTitle">第一条</span> 本学会的名称为长江技术经济学会（Changjiang Technology and Economy Society，CTES）。</span><br />
            <span><span className="paragraphTitle">第二条</span> 本学会是由从事长江流域技术经济相关工作的企事业单位和个人自愿结成的全国性、学术性、非营利性社会团体。</span><br />
            <span><span className="paragraphTitle">第三条</span> 本学会的宗旨：坚持四项基本原则和改革开放。以习近平新时代中国特色社会主义思想为指导，通过组织开展理论研究、学术交流、技术咨询等活动，进行长江流域经济建设的研究。为长江流域的开发开放，为长江经济带的协调发展建言献策。</span><br />
            <span className="paragraphContent">本学会遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，弘扬爱国主义精神，遵守社会道德风尚，自觉加强诚信自律建设。</span><br />
            <span><span className="paragraphTitle">第四条</span> 本学会坚持中国共产党的全面领导，根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。</span><br />
            <span><span className="paragraphTitle">第五条</span> 本学会接受业务主管单位科学技术部和社团登记管理机关民政部的业务指导和监督管理。</span><br />
            <span><span className="paragraphTitle">第六条</span> 本学会住所设在湖北省武汉市。</span>
          </p>
          <div className="contentOneSubhead"><span>第二章 业务范围</span></div>
          <p>
            <span><span className="paragraphTitle">第七条</span> 本学会的业务范围是：</span><br />
            <span className="paragraphContent">（一）为践行长江生态文明理念，推动长江经济带高质量发展，组织开展长江流域自然资源、区域经济、水利能源、交通航运、生态环境等方面的专题研究，对关系长江流域生态保护、经济发展的重大课题组织专家调研论证，为政府部门决策提出咨询意见和建议。</span><br />
            <span className="paragraphContent">（二）组织长江流域技术经济的调查研究与科学考察活动。</span><br />
            <span className="paragraphContent">（三）与国内外有关学术团体建立联系，组织开展国内外学术交流活动，加强国际合作。</span><br />
            <span className="paragraphContent">（四）开展人员技术与管理培训，为会员和科技工作者更新知识、提升技术与管理水平服务。</span><br />
            <span className="paragraphContent">（五）开展技术推广、技术咨询、技术评价与鉴定服务工作；承担科学研究与咨询项目；接受委托承办或根据学科发展需要举办国际、国内展览会，推广长江流域科学技术成果；发布年度长江技术经济重大科技问题。</span><br />
            <span className="paragraphContent">（六）按照有关规定和程序要求，举荐科学技术人才，开展科技奖励工作，组织制定并发布学会团体标准。</span><br />
            <span className="paragraphContent">（七）汇集、整理、分析国内外技术信息，消化、吸收先进技术，为长江流域各省（自治区、直辖市）经济建设提供智力支持。</span><br />
            <span className="paragraphContent">（八）依照有关规定,主办长江技术经济学术期刊，编辑出版学术书籍、报刊及科普刊物（含音像等多媒体产品）。</span><br />
            <span className="paragraphContent">（九）组织长江技术经济科普创作，普及长江科学技术知识，传播科学思想、科学精神、科学方法，宣传长江生态文明理念，增强民众生态保护意识。</span><br />
            <span>业务范围中属于法律法规规章规定须经批准的事项，依法经批准后开展。</span>
          </p>
          <div className="contentOneSubhead"><span>第三章 会 员</span></div>
          <p>
            <span><span className="paragraphTitle">第八条</span> 本学会会员包括单位会员和个人会员。</span><br />
            <span className="paragraphContent">从事长江流域技术经济相关工作的企事业单位等均可申请，为本学会的单位会员。</span><br />
            <span className="paragraphContent">对长江流域技术经济有研究的专家、学者及在国内外热心长江技术经济发展的人士，可由本人申请或本学会邀请，作为本学会个人会员。</span><br />
            <span><span className="paragraphTitle">第九条</span> 申请加入本学会的会员，必须具备下列条件：</span><br />
            <span className="paragraphContent">（一）拥护本学会的章程；</span><br />
            <span className="paragraphContent">（二）有加入本学会的意愿；</span><br />
            <span className="paragraphContent">（三）在本学会的业务领域内具有一定的影响；</span><br />
            <span className="paragraphContent">（四）愿意积极参加本学会活动。</span><br />
            <span><span className="paragraphTitle">第十条</span> 会员入会的程序是：</span><br />
            <span className="paragraphContent">（一）个人会员：个人会员通过本学会网站会员管理系统登记申请，经理事会审核通过后入会；</span><br />
            <span className="paragraphContent">（二）单位会员：由要求入会的单位向本学会提交书面申请，经理事会讨论通过；</span><br />
            <span className="paragraphContent">（三）由理事会或理事会授权的机构发给个人会员和单位会员会员证。</span><br />
            <span><span className="paragraphTitle">第十一条</span> 会员享有下列权利：</span><br />
            <span className="paragraphContent">（一）本学会的选举权、被选举权和表决权。</span><br />
            <span className="paragraphContent">（二）参加本学会所举办的活动。</span><br />
            <span className="paragraphContent">（三）对本学会的工作有批评建议权和监督权。</span><br />
            <span className="paragraphContent">（四）得到本学会的各种信息、学会刊物、学术资料和本学会服务的优先权。</span><br />
            <span className="paragraphContent">（五）入会自愿，退会自由。</span><br />
            <span><span className="paragraphTitle">第十二条</span> 会员履行下列义务：</span><br />
            <span className="paragraphContent">（一）遵守本学会章程。</span><br />
            <span className="paragraphContent">（二）执行本学会的决议。</span><br />
            <span className="paragraphContent">（三）维护本学会的合法权益。</span><br />
            <span className="paragraphContent">（四）完成本学会所委托的工作。</span><br />
            <span className="paragraphContent">（五）按照规定交纳会费。</span><br />
            <span className="paragraphContent">（六）向本学会反映情况，提供有关信息资料。</span><br />
            <span><span className="paragraphTitle">第十三条</span> 会员退会应书面通知本学会，并交回会员证书。</span><br />
            <span className="paragraphContent">会员如两年不交纳会费或不参加本学会活动的，视为自动退会。</span><br />
            <span><span className="paragraphTitle">第十四条</span> 会员如有严重违反本章程的行为，经理事会或常务理事会表决通过，予以除名。</span>
          </p>
          <div className="contentOneSubhead"><span>第四章 组织机构和负责人产生、罢免</span></div>
          <p>
            <span><span className="paragraphTitle">第十五条</span> 本学会的最高权力机构是会员代表大会，会员代表大会的职权是：</span><br />
            <span className="paragraphContent">（一）制定和修改章程；</span><br />
            <span className="paragraphContent">（二）选举和罢免理事；</span><br />
            <span className="paragraphContent">（三）审议理事会的工作报告和财务报告；</span><br />
            <span className="paragraphContent">（四）制定和修改会费标准；</span><br />
            <span className="paragraphContent">（五）决定终止事宜；</span><br />
            <span className="paragraphContent">（六）决定其他重大事宜。</span><br />
            <span><span className="paragraphTitle">第十六条</span> 会员代表大会须有2/3以上的会员代表出席方能召开，其决议须经到会会员代表半数以上表决通过方能生效。</span><br />
            <span><span className="paragraphTitle">第十七条</span> 会员代表大会每届5年。因特殊情况需提前或延期换届的，须由理事会表决通过，报业务主管单位审查同意并经社团登记管理机关批准同意。延期换届时间最长不超过1年。</span><br />
            <span><span className="paragraphTitle">第十八条</span> 理事会是会员代表大会的执行机构，在会员代表大会闭会期间领导本学会开展日常工作，对会员代表大会负责。</span><br />
            <span><span className="paragraphTitle">第十九条</span> 理事会的职权是：</span><br />
            <span className="paragraphContent">（一）执行会员代表大会的决议；</span><br />
            <span className="paragraphContent">（二）选举和罢免理事长、副理事长、秘书长及常务理事；</span><br />
            <span className="paragraphContent">（三）筹备召开会员代表大会；</span><br />
            <span className="paragraphContent">（四）向会员代表大会报告工作和财务状况；</span><br />
            <span className="paragraphContent">（五）决定会员的吸收和除名；</span><br />
            <span className="paragraphContent">（六）决定办事机构、分支机构、代表机构和实体机构的设立、变更和终止；</span><br />
            <span className="paragraphContent">（七）决定副秘书长、各机构主要负责人的聘任；</span><br />
            <span className="paragraphContent">（八）领导本学会各机构开展工作；</span><br />
            <span className="paragraphContent">（九）制定内部管理制度；</span><br />
            <span className="paragraphContent">（十）决定其他重大事项。</span><br />
            <span><span className="paragraphTitle">第二十条</span> 理事会须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。</span><br />
            <span><span className="paragraphTitle">第二十一条</span> 理事会每年至少召开一次会议，情况特殊的可采用通讯形式召开。</span><br />
            <span><span className="paragraphTitle">第二十二条</span> 本学会设立常务理事会。常务理事会由理事会选举产生，人数不超过理事人数的1/3。在理事会闭会期间行使第十九条第一、三、五、六、七、八、九项的职权，对理事会负责。</span><br />
            <span><span className="paragraphTitle">第二十三条</span> 常务理事会须有2/3以上常务理事出席方能召开，其决议须经到会常务理事2/3以上表决通过方能生效。</span><br />
            <span><span className="paragraphTitle">第二十四条</span> 常务理事会至少每半年召开一次，情况特殊的可采用通讯形式召开。</span><br />
            <span><span className="paragraphTitle">第二十五条</span> 本学会的理事长、副理事长、秘书长必须具备下列条件：</span><br />
            <span className="paragraphContent">（一）坚持中国共产党的路线、方针、政策，政治素质好；</span><br />
            <span className="paragraphContent">（二）在本学会业务领域内有较大影响；</span><br />
            <span className="paragraphContent">（三）最高任职年龄不超过70周岁，秘书长为专职；</span><br />
            <span className="paragraphContent">（四）身体健康，能坚持正常工作；</span><br />
            <span className="paragraphContent">（五）未受过剥夺政治权利刑事处罚的；</span><br />
            <span className="paragraphContent">（六）具有完全民事行为能力。</span><br />
            <span><span className="paragraphTitle">第二十六条</span> 本学会理事长、副理事长、秘书长如超过最高任职年龄的，须经理事会表决通过，报业务主管单位审查并经社团登记管理机关批准同意后，方可任职。</span><br />
            <span><span className="paragraphTitle">第二十七条</span> 本学会理事长、副理事长、秘书长每届任期5年，连任不得超过两届。因特殊情况需延长任期的，须经会员代表大会2／3以上会员代表表决通过，报业务主管单位审查并经社团登记管理机关批准同意后方可任职。</span><br />
            <span><span className="paragraphTitle">第二十八条</span> 理事长为本学会法定代表人。因特殊情况，经理事长委托、理事会同意，报业务主管单位审核同意并经社团登记管理机关批准后，可以由副理事长或秘书长担任法定代表人。聘任或向社会公开招聘的秘书长不得任本学会法定代表人。</span><br />
            <span className="paragraphContent">法定代表人代表本学会签署有关重要文件。</span><br />
            <span className="paragraphContent">本学会法定代表人不得兼任其他团体的法定代表人。</span><br />
            <span><span className="paragraphTitle">第二十九条</span> 本学会理事长行使下列职权：</span><br />
            <span className="paragraphContent">（一）召集和主持理事会、常务理事会；</span><br />
            <span className="paragraphContent">（二）检查会员代表大会、理事会、常务理事会决议的落实情况。</span><br />
            <span><span className="paragraphTitle">第三十条</span> 本学会秘书长行使下列职权：</span><br />
            <span className="paragraphContent">（一）主持办事机构开展日常工作，组织实施年度工作计划；</span><br />
            <span className="paragraphContent">（二）协调各分支机构、代表机构、实体机构开展工作；</span><br />
            <span className="paragraphContent">（三）提名副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交理事会或常务理事会决定；</span><br />
            <span className="paragraphContent">（四）决定办事机构、代表机构、实体机构专职工作人员的聘用；</span><br />
            <span className="paragraphContent">（五）处理其他日常事务。</span><br />
            <span><span className="paragraphTitle">第三十一条</span> 本学会在本章程规定的宗旨和业务范围内，根据工作需要设立分支机构、代表机构。本学会的分支机构、代表机构是本学会的组成部分，不具有法人资格，不得另行制订章程，不得发放任何形式的登记证书，在本学会授权的范围内开展活动、发展会员，法律责任由本学会承担。</span><br />
            <span><span className="paragraphTitle">第三十二条</span> 分支机构、代表机构开展活动，应当使用冠有本学会名称的规范全称，并不得超出本学会的业务范围。</span><br />
            <span><span className="paragraphTitle">第三十三条</span> 本学会不设立地域性分支机构，不在分支机构、代表机构下再设立分支机构、代表机构。</span><br />
            <span><span className="paragraphTitle">第三十四条</span> 本学会的分支机构、代表机构名称不以各类法人组织的名称命名，不在名称中冠以“中国”、“中华”、“全国”、“国家”等字样，并以“分会”、“专业委员会”、“工作委员会”、“专项基金管理委员会”、“代表处”、“办事处”等字样结束。</span><br />
            <span><span className="paragraphTitle">第三十五条</span> 分支机构、代表机构的负责人，年龄不得超过70周岁，连任不超过2届。</span><br />
            <span><span className="paragraphTitle">第三十六条</span> 分支机构、代表机构的财务必须纳入本学会法定账户统一管理。</span><br />
            <span><span className="paragraphTitle">第三十七条</span> 本学会在年度工作报告中将分支机构、代表机构的有关情况报送社团登记管理机关。同时，将有关信息及时向社会公开，自觉接受社会监督。</span><br />
          </p>
          <div className="contentOneSubhead"><span>第五章 资产管理、使用原则</span></div>
          <p>
            <span><span className="paragraphTitle">第三十八条</span> 本学会经费来源：</span><br />
            <span className="paragraphContent">（一）会费；</span><br />
            <span className="paragraphContent">（二）捐赠；</span><br />
            <span className="paragraphContent">（三）政府资助；</span><br />
            <span className="paragraphContent">（四）在核准的业务范围内开展活动和服务的收入；</span><br />
            <span className="paragraphContent">（五）利息；</span><br />
            <span className="paragraphContent">（六）其他合法收入。</span><br />
            <span><span className="paragraphTitle">第三十九条</span> 本学会按照国家有关规定收取会员会费。</span><br />
            <span><span className="paragraphTitle">第四十条</span> 本学会经费必须用于本章程规定的业务范围和事业的发展，不得在会员中分配。本学会开展表彰奖励活动不收取任何费用。</span><br />
            <span><span className="paragraphTitle">第四十一条</span> 本学会建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。</span><br />
            <span><span className="paragraphTitle">第四十二条</span> 本学会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。</span><br />
            <span><span className="paragraphTitle">第四十三条</span> 本学会的资产管理必须执行国家规定的财务管理制度，接受会员代表大会和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。</span><br />
            <span><span className="paragraphTitle">第四十四条</span> 本学会换届或更换法定代表人之前，必须进行财务审计。</span><br />
            <span><span className="paragraphTitle">第四十五条</span> 本学会的资产，任何单位、个人不得侵占、私分和挪用。</span><br />
            <span><span className="paragraphTitle">第四十六条</span> 本学会专职工作人员的工资和保险、福利待遇，参照国家对事业单位的有关规定执行。</span>
          </p>
          <div className="contentOneSubhead"><span>第六章 章程的修改程序</span></div>
          <p>
            <span><span className="paragraphTitle">第四十七条</span> 对本学会章程的修改,须经理事会表决，报会员代表大会审议通过。</span><br />
            <span><span className="paragraphTitle">第四十八条</span> 本学会修改的章程，须在会员代表大会通过后15日内报业务主管单位审查同意，并报社团登记管理机关核准后生效。</span>
          </p>
          <div className="contentOneSubhead"><span>第七章 终止程序及终止后的财产处理</span></div>
          <p>
            <span><span className="paragraphTitle">第四十九条</span> 本学会完成宗旨或自行解散或由于分立、合并等原因，需要注销的，由理事会或常务理事会提出终止动议。</span><br />
            <span><span className="paragraphTitle">第五十条</span> 本学会终止动议须经会员代表大会表决通过，并报业务主管单位审查同意。</span><br />
            <span><span className="paragraphTitle">第五十一条</span> 本学会终止前，须在业务主管单位及有关机关指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。</span><br />
            <span><span className="paragraphTitle">第五十二条</span> 本学会经社团登记管理机关办理注销登记手续后即为终止。</span><br />
            <span><span className="paragraphTitle">第五十三条</span> 本学会终止后的剩余财产，在业务主管单位和社团登记管理机关监督下，按照国家有关规定，用于发展与本学会宗旨相关的事业。</span>
          </p>
          <div className="contentOneSubhead"><span>第八章 附 则</span></div>
          <p>
            <span><span className="paragraphTitle">第五十四条</span> 本章程经2022年12月7日第五届第三次会员代表大会表决通过。</span><br />
            <span><span className="paragraphTitle">第五十五条</span> 本章程的解释权属本学会的理事会。</span><br />
            <span><span className="paragraphTitle">第五十六条</span> 本章程自社团登记管理机关核准之日起生效。</span>
          </p>
        </div>
      </div>
    )
  }
}

export default Constitution