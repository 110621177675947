import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Menu } from 'antd'
// 设定路由必须使用getAuthority提取authority参数，使用setSideBar函数设置侧边栏
import { setSideBar, loginOut, getUsertype } from '../utils/auth'
import { MenuUnfoldOutlined, MenuFoldOutlined, PoweroffOutlined } from '@ant-design/icons'
import './index.scss'
import { adminRoutes } from '../routes/index'
const { SubMenu } = Menu;
// 如果使用Layout中Footer标签，需要引入Footer
const { Header, Content, Sider } = Layout

const routes = setSideBar(adminRoutes, 'admin')

class Frame extends React.Component {
  state = {
    collapsed: false,
    sideBarActive: '/adminpages/dashboard'
  }

  componentDidMount() {
    // console.log(this.props.history, 33333)
    this.setState({
      sideBarActive: this.props.location.pathname  // 解决页面刷新时侧边栏与内容不匹配
    })
    // console.log(this.props, 2222)
  }

  // 侧边菜单栏隐藏事件 
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  // 侧边菜单栏点击选中事件
  onSelect(e) {
    // console.log(e.key, 555555)
    // console.log(this.props, 22225)
    this.setState({
      sideBarActive: e.key
    })
  }

  // 生成多级菜单
  setMultistageMenu = (options) => {
    // console.log(options, 22222)
    return (<SubMenu key={options.path} title={
      <span>
        {options.icon}
        <span>{options.title}</span>
      </span>
    }>
      {options.children.map(optionItem => {
        if (optionItem.children === undefined) {
          return <Menu.Item key={optionItem.path} onClick={p => this.props.history.push(p.key)}>
            {optionItem.icon}
            <span>{optionItem.title}</span>
          </Menu.Item>
        } else {
          return this.setMultistageMenu(optionItem)
        }
      })}
    </SubMenu>)
  }

  // 退出登录
  logout = () => {
    loginOut()
  }

  render() {
    let { children } = this.props
    // console.log(this.props, 7777)
    const title = (
      <span>
        <img style={{ width: '36px', height: '36px' }} src='/logo2.png' alt='长江经济科技学会中心' />
        <span style={{ marginLeft: '15px', fontSize: '16px', color: '#1890ff' }}>会员管理后台</span>
      </span>
    )
    return (
      <Layout className="layout-content">
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 999 }}>
          <div className="logo">
            {title}
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[this.state.sideBarActive]} onClick={e => this.onSelect(e)}>
            {routes.map(route => {
              // console.log(route.children, 2222) icon={<UserOutlined />} 
              if (route.children === undefined) {
                return getUsertype() == "超级管理员" || route.path.indexOf("kjcg") != -1 ? <Menu.Item key={route.path} onClick={p => this.props.history.push(p.key)}>
                  {/* <i className="iconfont icon-daochu"></i> */}
                  {route.icon}
                  <span>{route.title}</span>
                </Menu.Item> : ""
              } else {
                // console.log(route, 1111)
                return getUsertype() == "超级管理员" ? this.setMultistageMenu(route) : ""
              }
            })}
          </Menu>
        </Sider>
        <Layout className="site-layout" style={this.state.collapsed ? { paddingLeft: '80px' } : { paddingLeft: '200px' }}>
          <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            <span className="logOut" onClick={this.logout} >
              <PoweroffOutlined />
            </span>
          </Header>
          {/* <div className="test"></div> */}
          <div className='adminContent'>
            <Content
              className="site-layout-background"
              style={{
                // margin: '16px',
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </div>
        </Layout>
      </Layout >
    )
  }
}

export default withRouter(Frame)
