import React from 'react'
import {Form, Select, Input, Radio, DatePicker, Modal, Button, message, Upload} from 'antd'
// import moment from 'moment'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './Personmodal.scss'
import { DebounceSelect } from "../select/DebounceSelect";
import {
  delFileInDBAndLocal,
  getAttachByUserAccount,
  getAttachInArticle,
  getOrgByName,
  multipleUploadFile,
  regInsertOrg
} from "../../service/api";
import qs from 'qs'
import {UploadOutlined} from "@ant-design/icons";
const { Option } = Select;
const {TextArea} = Input;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  }
};

const party = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众'];
const title = ['正高级职称', '副高级职称', '中级职称', '初级职称', '无', '其他'];
const memberTagOption = ['理事长', '常务副理事长', '副理事长', '秘书长', '常务理事', '副秘书长', '理事', '会员'];
const qualification = ['博士后', '博士研究生', '硕士研究生', '本科', '专科'];
const IDCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const SpaceReg = /^(?!(\s+$))/;//纯空格

class Personmodal extends React.Component {
  state = {
    nowZc: this.props.dataBase.zc,
    birthday: this.props.dataBase.birthday._i,
    loading: false,
    orgObj: {
      label: this.props.dataBase.company.label,
      value: this.props.dataBase.company.orgId
    },
    addOrgName: "",
    addOrgButtonFlag: false,
    showAddOrgModalFlag: false,
    fjList:[]
  };
  componentDidMount() {
    if (this.props.dataBase.account) {
      getAttachByUserAccount({ account: this.props.dataBase.account }).then((res) => {
        let fjListExtra = []
        if (res.data.code == 200) {
          res.data.data.length && res.data.data.forEach(item => {
            fjListExtra.push({
              uid: item.id,
              name: item.fileName,
              url: item.fileUrl,
            })
          })
        }
        this.setState({
          fjList: fjListExtra
        })
      })
    }
  }

  // 生日赋值
  timeChange = (date, dateString) => {
    this.setState({
      birthday: dateString
    })
  };

  // 取消
  handleCancel = () => {
    const handleCancel = this.props.handleCancel;
    handleCancel()
  };

  // 职称为其他时需要自己输入
  zcCheck = value => {
    this.setState({
      nowZc: value
    })
  };

  // 提交/添加
  handleOk = e => {
    console.log(e, 3333)
    let birthday = this.state.birthday;
    let allData = e;
    let toP = this.props.newDataBase;
    allData.birthday = birthday;
    // allData.promiseWord = apliction
    if (allData.sex === 1) {
      allData.sex = '男'
    } else {
      allData.sex = '女'
    }
    if (allData.zc == "其他") {
      allData.zc = allData.otherZc
    }
    allData.company = this.state.orgObj.label;
    let companyAttach = '';
    if (this.state.fjList.length > 0) {
      let attachArr = [];
      this.state.fjList.forEach(item => {
        attachArr.push(item.uid);
      });
      companyAttach = JSON.stringify(attachArr);
    }
    allData.companyAttach = companyAttach;
    allData.orgId = this.state.orgObj.value;

    allData.name = allData.name?.trim();
    allData.city = allData.city?.trim();
    allData.national = allData.national?.trim();
    allData.recommendUnit = allData.recommendUnit?.trim();
    allData.zw = allData.zw?.trim();
    allData.address = allData.address?.trim();
    allData.tel = allData.tel?.trim();
    allData.weChat = allData.weChat?.trim();
    allData.degree = allData.degree?.trim();
    allData.graduatedFrom = allData.graduatedFrom?.trim();
    allData.major1 = allData.major1?.trim();
    allData.major2 = allData.major2?.trim();
    allData.researchField = allData.researchField?.trim();
    // allData.vId = `person_${allData.mobileTel}${new Date().getTime()}`
    // allData.confirmStatus = '0'
    toP({ data: allData, flag: '0' })
  };

  fetchOrgList = (orgname) => {
    // console.log('fetching user', orgname);
    let param = { 'name': orgname };
    return getOrgByName(param).then(res => {
      if (res.data.data.length < 1) {
        this.setState({
          addOrgButtonFlag: true
        });
      } else {
        this.setState({
          addOrgButtonFlag: false
        });
      }
      return res.data.data.map(org => ({
        label: org.orgName,
        value: org.orgId
      }))
    })
  };
  handleOrgChange = (value) => {
    // console.log(`selected ：`+JSON.stringify(value));
    this.setState({
      orgObj: value
    })
  };
  showAddOrgModal = () => {
    this.setState({
      showAddOrgModalFlag: true
    })
  };

  handleAddOrgOk = () => {

    const param = qs.stringify({ 'orgName': this.state.addOrgName });
    regInsertOrg(param).then(res => {
      let data = res.data;
      if (data.code === 200) {
        message.success('新增成功，请重新搜索单位！');
        this.setState({
          showAddOrgModalFlag: false
        })
        // console.log(44, data.data);
      } else if (data.code === 4010) {
        //账号已存在
        return message.error('此单位名称已存在，请核实后再填写！')
      }
    }).catch(
        () => { return message.error('新增单位异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！') }
    );
  };

  handleAddOrgCancel = () => {
    this.setState({
      showAddOrgModalFlag: false
    })
  };
  onOrgNameChange = e => {
    this.setState({
      addOrgName: e.target.value.trim()
    })
  };
  //附件上传
  //文件上传完成后执行的操作
  fjHandleChange = info => {
    console.log(111, info)
    let fjListExtra = [...info.fileList];
    fjListExtra = fjListExtra.map(file => {
      if (file.response) {
        file.uid = file.response.uid;
        file.url = file.response.url;
        file.name = file.response.fileName;
      }
      return file;
    });

    this.setState({
      fjList: fjListExtra
    });
  };
  fjRemove = file => {
    const fjListExtra = this.state.fjList.filter(item => item.url !== file.url);
    this.setState({
      fjList: fjListExtra
    });

    const param = qs.stringify({
      'id': file.uid,
      'fileUrl': file.url
    });
    delFileInDBAndLocal(param).then().catch();
  };
  render() {
    // console.log(dataBase, 1111)
    return (
      <Modal
        width={800}
        destroyOnClose
        centered
        title={`${this.props.modalTitle ? '编辑信息' : '查看信息'}`}
        visible={this.props.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div style={{ width: "100%" }}>
          <Form
            {...layout}
            name="basic"
            initialValues={this.props.dataBase}
            onFinish={this.handleOk}
            labelAlign="center"
          >
            {this.props.dataBase.infoEditStatus == "2" ?
                <Form.Item
                    label="审核意见"
                    name="opinion"
                >
                  <TextArea autoSize readOnly></TextArea>
                </Form.Item> : null}
            <div className="baseTitle"><span>个人基本信息</span></div>
            <Form.Item
              name="name"
              label="姓名"
              rules={[
                {
                  required: true,
                  message: '请填写姓名',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input disabled={this.props.userType != "管理员" && this.props.modalTitle} />
            </Form.Item>
            <Form.Item
              name="memberTag1"
              label="学会职务"
              rules={[
                {
                  required: true,
                  message: '请选择学会职务',
                }
              ]}
            >
              <Select placeholder="请选择学会职务" disabled={this.props.userType != "管理员" && this.props.modalTitle}>
                {memberTagOption.map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>
            {this.props.modalTitle ? <Form.Item
              name="sex"
              label="性别"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Radio.Group name="sex" disabled={this.props.userType != "管理员" && this.props.modalTitle}>
                <Radio value={1}>男</Radio>
                <Radio value={2}>女</Radio>
              </Radio.Group>
            </Form.Item> : <Form.Item
              name="sex"
              label="性别"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>}
            <Form.Item
              name="city"
              label="籍贯"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input placeholder="省市，例如：湖北省武汉市" />
            </Form.Item>
            <Form.Item
              name="national"
              label="民族"
              rules={[
                {
                  required: true,
                  message: '请填写民族',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="party"
              label="党派"
              rules={[
                {
                  required: true,
                  message: '请选择党派',
                }
              ]}
            >
              <Select placeholder="请选择党派">
                {party.map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>
            {this.props.modalTitle ?
              <Form.Item
                name="birthday"
                label="出生年月"
                rules={[
                  {
                    required: true,
                    message: '请填写出生年月',
                  }
                ]}
              >
                <DatePicker locale={locale} placeholder="请选择时间" onChange={this.timeChange} loading={this.state.loading} disabled={this.props.userType != "管理员" && this.props.modalTitle} />
              </Form.Item> : <Form.Item
                name="birthday"
                label="出生年月"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>}
            <Form.Item
              name="zw"
              label="职务"
              rules={[
                {
                  required: true,
                  message: '请填写职务',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zc"
              label="职称"
              rules={[
                {
                  required: true,
                  message: '请选择职称',
                }
              ]}
            >
              <Select placeholder="请选择职称" onChange={this.zcCheck}>
                {title.map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>
            {this.state.nowZc == "其他" ?
              <Form.Item
                name="otherZc"
                label="其他职称"
                rules={[
                  {
                    required: true,
                    message: '请输入其他职称',
                  }
                ]}
              >
                <Input style={{ width: 150 }} placeholder="若选其他，请填写" />
              </Form.Item>
              : null
            }
            <Form.Item
              name="recommendUnit"
              label="推荐单位"
              rules={[
                {
                  required: false,
                  message: '请填写推荐单位',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="idCard"
              label="身份证号"
              rules={[
                {
                  required: true,
                  message: '请填写身份证号'
                },
                {
                  pattern: IDCardReg,
                  message: '请填写正确身份证号',
                }
              ]}
            >
              <Input disabled={this.props.userType != "管理员" && this.props.modalTitle} />
            </Form.Item>
            <div className="baseTitle"><span>联系方式</span></div>
            <Form.Item
              name="company"
              label="工作单位"
              help="请先输入单位名称搜索选择对应单位，如未出现可新增单位"
              rules={[
                {
                  required: true,
                  message: '请填写工作单位',
                }
              ]}
            >
              {/*<Input />*/}
              <DebounceSelect
                value={this.state.orgObj}
                placeholder="搜索单位"
                fetchOptions={this.fetchOrgList}
                onChange={this.handleOrgChange}
                style={{
                  width: '100%',
                }}
                disabled={!this.props.modalTitle}
              />
            </Form.Item>
            {this.state.addOrgButtonFlag ?
                <Form.Item name="orgNameAdd" label=" ">
                  没有找到对应单位？试试<Button onClick={this.showAddOrgModal}>新增单位</Button>
                </Form.Item> : ""
            }
            <Modal title="新增单位" visible={this.state.showAddOrgModalFlag}
                   onOk={this.handleAddOrgOk}
                   onCancel={this.handleAddOrgCancel}
                   okText="确定"
                   cancelText="取消"
            >
              <Form.Item
                  className='unitName'
                  name="unitName"
                  rules={[
                    {
                      pattern: SpaceReg,
                      message: '输入内容不能为空'
                    }
                  ]}
              >
                <Input placeholder="输入单位名称" value={this.state.addOrgName} onChange={this.onOrgNameChange}/>
              </Form.Item>
            </Modal>

            <Form.Item
                name="fjList"
                label="所在单位推荐函"
            >
              <Upload
                  disabled={!this.props.modalTitle}
                  name="file"
                  multiple={true}
                  action={multipleUploadFile}
                  fileList={this.state.fjList}
                  data={{ type: 'mailPath', opt: 'saveDB' }}
                  onChange={this.fjHandleChange}
                  onRemove={this.fjRemove}
              >
                <Button disabled={!this.props.modalTitle} icon={<UploadOutlined />}>上传</Button>
              </Upload>

            </Form.Item>
            <Form.Item
              name="address"
              label="通讯地址"
              rules={[
                {
                  required: true,
                  message: '请填写通讯地址',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mobileTel"
              label="手机"
              rules={[
                {
                  required: true,
                  message: '请填写手机联系方式'
                },
                {
                  pattern: phoneReg,
                  message: '请填写正确手机联系方式'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="tel"
              label="电话"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="weChat"
              label="微信号"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: '输入不是有效电子邮箱',
                },
                {
                  required: true,
                  message: '请填写电子邮箱',
                }
              ]}
            >
              <Input />
            </Form.Item>
            <div className="baseTitle"><span>专业信息</span></div>
            <Form.Item
              name="education"
              label="最高学历"
              rules={[
                {
                  required: true,
                  message: '请选择最高学历',
                }
              ]}
            >
              <Select placeholder="请选择最高学历">
                {qualification.map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="degree"
              label="最高学位"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="graduatedFrom"
              label="毕业院校"
              rules={[
                {
                  required: true,
                  message: '请填写毕业院校',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="major1"
              label="专业1"
              rules={[
                {
                  required: true,
                  message: '请填写主要专业',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="major2"
              label="专业2"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="researchField"
              label="专业研究方向"
              rules={[
                {
                  required: true,
                  message: '请填写主要专业研究方向',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input placeholder="可填多个" />
            </Form.Item>
            <Form.Item
              name="promiseWord"
              label="入会声明"
            >
              <Input.TextArea disabled />
            </Form.Item>

            {/* <Form.Item> */}
            <div className="buttonWrapper">
              {this.props.modalTitle ? <Button type="primary" htmlType="submit">
                保存
              </Button> : true}
            </div>
            {/* </Form.Item> */}
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Personmodal
