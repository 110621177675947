import React from 'react'
import { Modal} from 'antd'
import './Experthelp.scss'

class Experthelp extends React.Component {

// 关闭
handleCancel = () => {
  const handleCancel = this.props.handleCancel;
  handleCancel()
};

render() {
    return (
      <Modal
        width={800}
        destroyOnClose
        centered
        title='专家-帮助文档'
        visible={this.props.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div style={{width: "100%"}}>
          <p className="paragraph">
            <span className="paragraphTitle">长江技术经济学会（Changjiang Technology and Economy Society缩写CTES）成立于1994年4月，秘书处设在湖北省武汉市。学会是由国家科技部领导的跨地区、跨部门、跨学科的国家级学术团体，学会始终秉承为流域经济社会发展服务的宗旨，紧密结合长江流域经济社会发展实际，着眼于长江流域的自然资源、区域经济、水利能源、交通航运、生态环境等技术经济问题，通过理论研究、学术交流、技术咨询等方式，积极为中央和地方政府建言献策，形成了许多有意义、有创建、有价值的重大学术成果，有力推动了长江流域经济社会发展，对促进长江经济带的开放开发乃至整个长江流域的经济社会发展做出了重要贡献。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">联系人：</span><br />
            {/* <span className="paragraphContent"><span className="contactPeson">方&nbsp;&nbsp;&nbsp;&nbsp;择</span>027-82829065/17612730587</span><br /> */}
            {/* <span className="paragraphContent"><span className="contactPeson">杨恩</span>027-82926221/15827296951</span><br /> */}
            <span className="paragraphContent"><span className="contactPeson">王黎明</span>027-82926221/18994903640</span><br />
          </p>
        </div>
      </Modal>
    )
  }
}

export default Experthelp
