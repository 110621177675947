//管理项目状态
const PROCESS_CG = "21";//草稿
const PROCESS_JBRCS = "23";//经办人初审
const PROCESS_FZRCS = "24";//负责人初审
const PROCESS_GSZ = "25";//公示中
const PROCESS_GSWC = "26";//公示完成
const PROCESS_THXG = "12";//退回修改
const PROCESS_GSYYY = "14";//公示有异议
const PROCESS_ZBHY = "27";//准备会议
const PROCESS_WJ = "28";//完结
const PROCESS_CG_WZ = "草稿";
const PROCESS_JBRCS_WZ = "经办人初审";
const PROCESS_FZRCS_WZ = "负责人初审";
const PROCESS_GSZ_WZ = "公示中";
const PROCESS_GSWC_WZ = "公示完成";
const PROCESS_THXG_WZ = "退回修改";
const PROCESS_GSYYY_WZ = "公示有异议";
const PROCESS_ZBHY_WZ = "准备会议";
const PROCESS_WJ_WZ = "完结";
export {
  PROCESS_CG, PROCESS_JBRCS, PROCESS_FZRCS, PROCESS_GSZ, PROCESS_GSWC, PROCESS_THXG, PROCESS_GSYYY, PROCESS_ZBHY, PROCESS_WJ,
  PROCESS_CG_WZ, PROCESS_JBRCS_WZ, PROCESS_FZRCS_WZ, PROCESS_GSZ_WZ, PROCESS_GSWC_WZ, PROCESS_THXG_WZ, PROCESS_GSYYY_WZ, PROCESS_ZBHY_WZ, PROCESS_WJ_WZ
}

// 获取不同时间的时间节点
export function getDate(date, type) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  if (type === 'now') {
    return [year, month, day].map(formDate).join('-') + ' ' + [hour, minute, second].map(formDate).join(':')
  } else if (type === 'date') {
    return [year, month, day].map(formDate).join('-')
  } else if (type === 'dateTime') {
    return [year, month, day].map(formDate).join('-') + ' 00:00:00'
  } else if (type === 'firstDate') {
    return [year, month, 1].map(formDate).join('-')
  } else if (type === 'year') {
    return year
  }
}

// 补0函数
function formDate(data) {
  data = data.toString()
  return data[1] ? data : '0' + data
}

// 会员编码补0函数
export function formNumber(data) {
  let dataLength = data.toString().length
  if (dataLength === 1) {
    return '000' + data
  } else if (dataLength === 2) {
    return '00' + data
  } else if (dataLength === 3) {
    return '0' + data
  } else {
    return data
  }
}

// 获取系统
export function getSys() {
  let userAgent = navigator.userAgent.toLowerCase()
  let name = 'Unknown'
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows'
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone'
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac'
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    name = 'Unix'
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android'
    } else {
      name = 'Linux'
    }
  } else {
    name = 'Unknown'
  }
  return name
}

// json文件导出
// exportFileJSON(defaultData, 'example.json');
export function exportFileJSON(data = {}, filename = 'dataJSON.json') {
  if (typeof data === 'object') {
    data = JSON.stringify(data, null, 4);
  }
  // 导出数据
  const blob = new Blob([data], { type: 'text/json' }),
    e = new MouseEvent('click'),
    a = document.createElement('a');

  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
  a.dispatchEvent(e);
}
// json文件导入
/*使用方式
// 回显数据
const showImportData = (data) => {
  document.getElementById('importBox').innerHTML = JSON.stringify(data, null, 4);
}
// 导入文件的事件(原生输入，只要能获取文件就行)
document.getElementById('importBtn').onchange = (event) => {
  importFileJSON(event).then((res) => {
    console.log('读取到的数据', res);
    // 回显数据
    showImportData(res);
  }).catch((err) => {
    console.log(err);
  });
}
*/
export function importFileJSON(file) {
  return new Promise((resolve, reject) => {
    // 格式判断
    if (file.type !== 'application/json') {
      reject('仅允许上传json文件');
    }
    // 检验是否支持FileRender
    if (typeof FileReader === 'undefined') {
      reject('当前浏览器不支持FileReader');
    }

    // 执行读取json数据操作
    const reader = new FileReader();
    reader.readAsText(file); // 读取的结果还有其他读取方式，我认为text最为方便

    reader.onerror = (err) => {
      reject('json文件解析失败', err);
    }

    reader.onload = () => {
      const resultData = reader.result;
      if (resultData) {
        try {
          const importData = JSON.parse(resultData);
          resolve(importData);
        } catch (error) {
          reject('读取数据解析失败', error);
        }
      } else {
        reject('读取数据解析失败');
      }
    }
  });
}