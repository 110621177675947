import React from 'react'
//  FormOutlined
import { CommentOutlined, TrophyOutlined, GlobalOutlined, FormOutlined, YoutubeOutlined, FileOutlined, UserOutlined, UsergroupAddOutlined, TrademarkOutlined, MessageOutlined, SolutionOutlined, MailOutlined, CarryOutOutlined, SendOutlined, TeamOutlined, UsbOutlined, BankOutlined, LockOutlined, SafetyOutlined, VerifiedOutlined, SketchOutlined, ClusterOutlined, AreaChartOutlined, VideoCameraOutlined, ExceptionOutlined, EditOutlined, ContactsOutlined } from '@ant-design/icons'
import Login from '../pages/Login/Login'
import MailNotice from '../pages/MailNotice/index'
import Forgot from '../pages/ForgotPWD/Forgot'
import Register from '../pages/Register/Register'
import RegisterPerson from '../pages/Register/RegisterPerson'
import RegisterExpert from '../pages/Register/RegisterExpert'
import RegisterUnit from '../pages/Register/RegisterUnit'
import Error404 from '../pages/Error/404'

const commonRoutes = [
  {
    path: '/memberpages/xsjl',
    isShow: true,
    title: '学术交流',
    icon: <CommentOutlined />,
  },
  {
    path: '/memberpages/kjjl',
    isShow: true,
    title: '科技奖励',
    icon: <TrophyOutlined />,
  },
  {
    path: '/memberpages/ttbz',
    isShow: true,
    title: '团体标准',
    icon: <TeamOutlined />,
  },
  {
    path: '/memberpages/kxpj',
    isShow: true,
    title: '科学普及',
    icon: <GlobalOutlined />,
  }
]
const memberCommonRoutes = commonRoutes.map(item => {
  return { ...item, ...{ authority: ['member'] } }
})
const expertsCommonRoutes = commonRoutes.map(item => {
  return { ...item, ...{ authority: ['experts'] } }
})
const groupCommonRoutes = commonRoutes.map(item => {
  return { ...item, ...{ authority: ['group'] } }
})

export const mainRoutes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/forgot',
    component: Forgot
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/registerperson',
    component: RegisterPerson
  },
  {
    path: '/registerexpert',
    component: RegisterExpert
  },
  {
    path: '/registerunit',
    component: RegisterUnit
  },
  {
    path: '/404',
    component: Error404
  },
  {
    path: '/mailNotice',
    component: MailNotice
  },
];

export const adminRoutes = [
  {
    path: '/adminpages/dashboard',
    isShow: true,
    title: '信息管理',
    icon: <UsbOutlined />,
    authority: ['admin', 'editor']
  },
  {
    path: '/adminpages/experts',
    title: '专家管理',
    isShow: true,
    icon: <TrademarkOutlined />,
    authority: ['admin', 'editor']
  },
  {
    path: '/adminpages/personmanger',
    isShow: true,
    title: '会员管理',
    icon: <SolutionOutlined />,
    authority: ['admin', 'editor'],
    children: [

      {
        path: '/adminpages/unitmember',
        title: '单位会员',
        isShow: true,
        icon: <TeamOutlined />,
        authority: ['admin', 'editor']
      },
      {
        path: '/adminpages/personmember',
        title: '个人会员',
        isShow: true,
        icon: <UserOutlined />,
        authority: ['admin', 'editor']
      },
      {
        path: '/adminpages/directormember',
        title: '理事会员',
        isShow: true,
        icon: <ContactsOutlined />,
        authority: ['admin', 'editor']
      }
    ]
  },
  // {
  //   path: '/adminpages/videos',
  //   isShow: true,
  //   title: '视频管理',
  //   icon: <VideoCameraOutlined />,
  //   authority: ['admin', 'editor']
  // },
  {
    path: '/adminpages/article',
    isShow: true,
    title: '文章管理',
    icon: <EditOutlined />,
    authority: ['admin', 'editor'],
    children: [
      {
        path: '/adminpages/xsjl',
        title: '学术交流',
        isShow: true,
        icon: <EditOutlined />,
        authority: ['admin', 'editor']
      },
      {
        path: '/adminpages/kjjl',
        title: '科技奖励',
        isShow: true,
        icon: <EditOutlined />,
        authority: ['admin', 'editor']
      },
      {
        path: '/adminpages/ttbz',
        title: '团体标准',
        isShow: true,
        icon: <EditOutlined />,
        authority: ['admin', 'editor']
      },
      {
        path: '/adminpages/kxpj',
        title: '科学普及',
        isShow: true,
        icon: <EditOutlined />,
        authority: ['admin', 'editor']
      },
    ]
  },
  {
    path: '/adminpages/kjcg',
    isShow: true,
    title: '科技成果评价',
    icon: <FileOutlined />,
    authority: ['admin', 'editor']
  },
  {
    path: '/adminpages/administrators',
    isShow: true,
    title: '账户管理',
    icon: <VerifiedOutlined />,
    authority: ['admin', 'editor']
  },
  {
    path: '/adminpages/abnormal',
    isShow: true,
    title: '登录异常',
    icon: <ExceptionOutlined />,
    authority: ['admin', 'editor']
  },
  {
    path: '###1',
    isShow: true,
    title: '会员服务',
    icon: <SketchOutlined />,
    authority: ['admin', 'editor'],
    children: [
      {
        path: '###2',
        isShow: true,
        title: '邮箱',
        icon: <MailOutlined />,
        authority: ['admin', 'editor'],
        children: [
          {
            path: '/adminpages/OutBox',
            isShow: true,
            title: '发件箱',
            icon: <SendOutlined />,
            authority: ['admin', 'editor']
          },
          {
            path: '/adminpages/BeenSend',
            isShow: true,
            title: '发件日志',
            icon: <CarryOutOutlined />,
            authority: ['admin', 'editor']
          },
        ]
      },
      {
        path: '###3',
        isShow: true,
        title: '短信',
        icon: <MessageOutlined />,
        authority: ['admin', 'editor'],
        children: [
          {
            path: '/adminpages/SMSOutBox',
            isShow: true,
            title: '发件箱',
            icon: <SendOutlined />,
            authority: ['admin', 'editor']
          },
          {
            path: '/adminpages/SMSBeenSend',
            isShow: true,
            title: '发件日志',
            icon: <CarryOutOutlined />,
            authority: ['admin', 'editor']
          },
        ]
      },
      {
        path: '###4',
        isShow: true,
        title: '统计分析',
        icon: <AreaChartOutlined />,
        authority: ['admin', 'editor'],
        children: [
          {
            path: '/adminpages/UnitChart',
            isShow: true,
            title: '单位会员',
            icon: <TeamOutlined />,
            authority: ['admin', 'editor']
          },
          {
            path: '/adminpages/PersonChart',
            isShow: true,
            title: '个人会员',
            icon: <UserOutlined />,
            authority: ['admin', 'editor']
          },
        ]
      }
    ]
  },
  {
    path: '/adminpages/org/orgIndex',
    isShow: true,
    title: '单位库管理',
    icon: <ClusterOutlined />,
    authority: ['admin', 'editor']
  }
  // {
  //   path: '/adminpages/information/demo',
  //   isShow: true,
  //   title: '资料共享',
  //   icon: <VerifiedOutlined />,
  //   authority: ['admin', 'editor']
  // },
  // {
  //   path: '/adminpages/information/ModalInfo',
  //   isShow: false,
  //   title: '资料共享2',
  //   icon: <VerifiedOutlined />,
  //   authority: ['admin', 'editor']
  // },

];

// 会员路由
export const memberRoutes = [
  {
    path: '/memberpages/home',
    isShow: true,
    title: '首页',
    icon: <BankOutlined />,
    authority: ['member']
  },
  {
    path: '/memberpages/baseinfo',
    isShow: false,
    title: '基本信息',
    icon: <BankOutlined />,
    authority: ['member']
  },
  ...memberCommonRoutes,
  // {
  //   path: '/memberpages/cloudforum',
  //   isShow: true,
  //   title: '云讲坛',
  //   icon: <AudioOutlined />,
  //   authority: ['member']
  // },
  {
    path: '/memberpages/kjcg',
    isShow: true,
    title: '科技成果评价',
    icon: <FileOutlined />,
    authority: ['member']
  },
  {
    path: '/memberpages/logininfo',
    isShow: true,
    title: '登录信息',
    icon: <LockOutlined />,
    authority: ['member']
  },
  // {
  //   path: '/memberpages/opinioninfo',
  //   isShow: true,
  //   title: '建言献策',
  //   icon: <FormOutlined />,
  //   authority: ['member']
  // },
  {
    path: '/memberpages/updatepwd',
    isShow: true,
    title: '登录密码',
    icon: <SafetyOutlined />,
    authority: ['member']
  }
];

// 专家路由
export const expertsRoutes = [
  {
    path: '/memberpages/home',
    isShow: true,
    title: '首页',
    icon: <BankOutlined />,
    authority: ['experts']
  },
  {
    path: '/memberpages/baseinfo',
    isShow: false,
    title: '基本信息',
    icon: <BankOutlined />,
    authority: ['experts']
  },
  {
    path: '/memberpages/examine',
    isShow: true,
    title: '奖励评审',
    icon: <FormOutlined />,
    authority: ['experts']
  },
  ...expertsCommonRoutes,
  // {
  //   path: '/memberpages/cloudforum',
  //   isShow: true,
  //   title: '云讲坛',
  //   icon: <AudioOutlined />,
  //   authority: ['experts']
  // },
  {
    path: '/memberpages/kjcgofexpert',
    isShow: true,
    title: '科技成果评价',
    icon: <FileOutlined />,
    authority: ['experts']
  },
  {
    path: '/memberpages/logininfo',
    isShow: true,
    title: '登录信息',
    icon: <LockOutlined />,
    authority: ['experts']
  },
  {
    path: '/memberpages/updatepwd',
    isShow: true,
    title: '登录密码',
    icon: <SafetyOutlined />,
    authority: ['experts']
  }
];

// 单位会员路由
export const groupRoutes = [
  {
    path: '/memberpages/home',
    isShow: true,
    title: '首页',
    icon: <BankOutlined />,
    authority: ['group']
  },
  {
    path: '/memberpages/baseinfo',
    isShow: false,
    title: '基本信息',
    icon: <BankOutlined />,
    authority: ['group']
  },
  ...groupCommonRoutes,
  // {
  //   path: '/memberpages/cloudforum',
  //   isShow: true,
  //   title: '云讲坛',
  //   icon: <AudioOutlined />,
  //   authority: ['group']
  // },
  {
    path: '/memberpages/logininfo',
    isShow: true,
    title: '登录信息',
    icon: <LockOutlined />,
    authority: ['group']
  },
  {
    path: '/memberpages/groupmember',
    isShow: true,
    title: '单位注册成员',
    icon: <UsergroupAddOutlined />,
    authority: ['group']
  },
  {
    path: '/memberpages/updatepwd',
    isShow: true,
    title: '登录密码',
    icon: <SafetyOutlined />,
    authority: ['group']
  }
];
