import React from 'react'
import { Form, Input, Button, message, Select, notification, Row, Col } from 'antd';
import { UserOutlined, LockOutlined, VerifiedOutlined } from '@ant-design/icons';
import './Login.scss'
import { login, sendCaptchaForLogin } from '../../service/api'
import logo from '../../assets/images/logo.jpg'
import user from '../../assets/images/login/user.png';
import password from '../../assets/images/login/password.png';
import captcha from '../../assets/images/login/captcha.png';
import { getSys } from '../../service/func'
import { setAuthority, setUsername, setUsertype, setInitPwd, setUserid } from '../../utils/auth'
import { encrypt } from '../../utils/stringUtil'
import { checkMobile } from '../../utils/stringUtil'
import qs from 'qs'
const { Option } = Select;
let timeChange

class Login extends React.Component {
  state = {
    loading: false,
    remiderVisible: false,
    time: 60,
    btnDisable: false,
    btnContent: '获取验证码',
    role: "",//选择的登录角色
  };

  //表单操作相关
  formRef = React.createRef();

  // 展示reminder
  // showReminder = () => {
  //   // console.log('show')
  //   this.setState({
  //     remiderVisible: true
  //   })
  // }

  // 隐藏reminder
  // hideReminder = () => {
  //   // console.log('hide')
  //   this.setState({
  //     remiderVisible: false
  //   })
  // }

  // 跳转至登录页面
  toRegister = e => {
    e.stopPropagation();
    this.props.history.push('/register')
  };

  // 跳转至找回密码页面
  toForgotPWD = e => {
    e.stopPropagation();
    this.props.history.push('/forgot')
  };

  // 专家注册
  toExpert = e => {
    e.stopPropagation();
    this.props.history.push('/RegisterExpert')
  };

  // 管理员登录
  adminLogin = () => {
    window.location.href = '/adminpages/dashboard'
  };

  // 专家、组织、个人登录
  memberLogin = () => {
    window.location.href = '/memberpages/home'
  };

  // 角色变更
  roleChange = (value) => {
    //重置现有验证信息
    this.setState({
      role: value
    })
    // this.formRef.current.validateFields()
  };

  // 登陆
  submit = (e) => {
    let loginData = e;
    let loginForm = new FormData();
    const loginRole = loginData.role;

    // 管理员验证码固定为010203
    loginForm.append('username', loginData.username + ";@￥" + loginData.role + ';@￥' + loginData.captcha);
    loginForm.append('password', encrypt(loginData.password));
    loginForm.append('device', getSys());
    // let data = []
    // data.push('sdfd')
    // setAuthority(JSON.stringify(data))
    // message.success('会员登录成功')
    // window.location.href = '/memberpages/baseinfo'
    login(loginForm).then(res => {
      // console.log(res,1111)
      if (res.data.code === 2010) {
        return message.error('注册申请还没审核，请耐心等待')
      } else if (res.data.code === 2003) {
        // return message.error('密码错误')
        return message.error("用户名或密码错误")
      } else if (res.data.code === 2006) {
        return message.error('账号已被锁定，请联系管理员!')
      } else if (res.data.code === 2007) {
        // return message.error('用户不存在或登录角色有误')
        return message.error("用户名或登录角色有误或密码错误")
      } else if (res.data.code === 2012) {
        return message.error('注册申请未通过')
      } else if (res.data.code === 2011) {
        return message.error('注册申请已被退回修改，请及时修改')
      } else if (res.data.code === 2014) {
        return message.error('手机号登录有误，请尝试使用身份证号登陆！')
      } else if (res.data.code === 2018) {
        return message.error('验证码有误或已失效，请重试！')
      }
      // return message.success('登录功能正在开发中...')
      let data = [];
      let authorities = res.data.data.authorities;

      for (let i in authorities) {
        data.push(authorities[i].authority)
        if (authorities[i].authority.indexOf('AccessToken:') !== -1) {
          let AccessToken = authorities[i].authority
          localStorage.setItem('ctesToken', AccessToken.split(':')[1])
        }
      }
      //非管理员的密码为初始密码是需要强制变更密码
      setInitPwd(data.indexOf('超级管理员') === -1 && (loginData.password === '123456' || loginData.password === 'Ctes@0203'))
      // console.log(111, res.data.data)
      if (res.data.data.name == "jbr") {
        message.success('经办人登录成功');
        setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('经办人');
        window.location.href = '/adminpages/kjcg';
        return
      } else if (res.data.data.name == "fzr") {
        message.success('负责人登录成功');
        setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('负责人');
        window.location.href = '/adminpages/kjcg';
        return
      } else if (res.data.data.name == "zyh") {
        message.success('登录成功');
        setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('专家审定');
        window.location.href = '/adminpages/kjcg';
        return
      }
      if (data.indexOf('超级管理员') !== -1) {
        message.success('管理员登录成功');
        setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('超级管理员');
        window.location.href = '/adminpages/dashboard';
      } else if (data.indexOf('专家用户') !== -1 && '1' === loginRole) {
        message.success('专家会员登录成功');
        setAuthority(JSON.stringify(["专家用户"]));
        // setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('专家用户');
        this.memberLogin();

      } else if (data.indexOf('单位用户') !== -1) {
        message.success('单位会员登录成功');
        setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('单位用户');
        this.memberLogin();

      } else if (data.indexOf('个人用户') !== -1 && '2' === loginRole) {
        message.success('会员登录成功');
        setAuthority(JSON.stringify(['个人用户']));
        // setAuthority(JSON.stringify(data));
        setUsername(res.data.data.name);
        setUsertype('个人用户');
        this.memberLogin();

      } else {
        return message.error('权限和所选角色不匹配');
      }
    })
  };

  clock = () => {
    let ti = this.state.time
    if (ti > 0) {
      //当ti>0时执行更新方法
      ti -= 1;
      this.setState({
        time: ti,
        btnContent: `稍候(${ti}s)`,
      });
    } else {
      //当ti=0时执行终止循环方法
      clearInterval(timeChange);
      this.setState({
        btnDisable: false,
        time: 60,
        btnContent: "获取验证码",
      });
    }
  };

  getCaptcha = () => {
    let username = this.formRef.current.getFieldsValue()['username']
    let role = this.formRef.current.getFieldsValue()['role']
    if (!username || !role) {
      return notification.warning({
        message: '提示',
        description: '请填写用户名及登录角色后，再获取验证码！'
      })
    }
    if (username.indexOf('admin') !== -1 || username.indexOf('ckyAdmin') !== -1 || username.indexOf('jbr') !== -1 || username.indexOf('fzr') !== -1 || username.indexOf('zyh') !== -1) {
      return notification.warning({
        message: '提示',
        description: '管理用户无需获取验证码！'
      })
    }

    const params = qs.stringify({
      'username': username
      , 'role': role
    });
    sendCaptchaForLogin(params).then(res => {
      if (200 === res.data.code) {
        notification.success({
          message: '提示',
          description: `验证码已发送到${res.data.data}，请查收！`
        });
        this.setState({
          btnDisable: true,
          btnContent: "稍候(60s)",
        });

        //每隔一秒执行一次clock方法,注意不要用clock()，定时器要的是一个函数而非函数执行结果
        timeChange = setInterval(this.clock, 1000);
      } else {
        return notification.error({
          message: '提示',
          description: res.data.errorMsg
        })
      }
    })
  };

  render() {
    return (
      <div className="login">
        <div className="loginLog">
          <img className="logoImage" src={logo} alt="logo" />
        </div>
        <div className="loginCenter">
          <div className="loginBox">
            <div className="loginHeader">
              <div className='title'>登录中心</div>
              <div className='subTitle'>本平台为互联网非涉密平台，严禁处理、传输国家秘密</div>
            </div>
            <div className="loginContent">
              <Form
                name="normal_login"
                className="login-form"
                onFinish={this.submit}
                ref={this.formRef}
              >
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: '请选择登录角色',
                    }
                  ]}
                >
                  <Select placeholder="请选择登录角色" onChange={this.roleChange}>
                    <Option value="1" key="1">专家用户</Option>
                    <Option value="2" key="2">个人会员</Option>
                    <Option value="3" key="3">单位会员</Option>
                    <Option value="4" key="4">管理用户</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="username"
                  rules={this.state.role != "3" ? [
                    {
                      validator: checkMobile
                    },
                    { required: true, message: '请输入用户名!' }
                  ] : [{ required: true, message: '请输入统一信用代码!' }]}
                >
                  <Input prefix={<img className="site-form-item-icon" src={user} alt="user" />} placeholder={this.state.role != "3" ? "手机号" : "联系人手机号或单位统一信用代码"} />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: '请输入密码!' }]}
                >
                  <Input
                    prefix={<img className="site-form-item-icon" src={password} alt="password" />}
                    type="password"
                    placeholder="请输入密码"
                  />
                </Form.Item>

                <Form.Item
                  name="captcha"
                  rules={[{ required: true, message: '请输入验证码' }]}
                >
                  <Row className='captchaBox'>
                    <Input className='captchaInput' prefix={<img className="site-form-item-icon" src={captcha} alt="captcha" />} placeholder="请输入验证码" />
                    <Button
                      className='captchaButton'
                      onClick={(e) => {
                        e.preventDefault();
                        this.getCaptcha();
                      }}
                      disabled={this.state.btnDisable}
                    >
                      {this.state.btnContent}
                    </Button>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" className="login-form-button">
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="loginOther">
              <div className="registerBtn" onClick={e => this.toRegister(e)}>会员注册</div>
              <div className="registerBtn" onClick={e => this.toExpert(e)}>专家注册</div>
              <div className="registerBtn" onClick={e => this.toForgotPWD(e)}>忘记密码？</div>
            </div>
            {/* <div className="loginExpert">
              <span>专家注册</span>
            </div> */}
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">
            {/*©2021 长江技术经济学会 版权所有*/}
            <ul>
              <li><span><a href="https://beian.miit.gov.cn/" target="_blank"
                rel="noopener noreferrer">鄂ICP备15018540号</a></span><span>
                  <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010202000926"
                    target="_blank"
                    rel="noopener noreferrer">公安备案号42010202000926</a>
                </span><span>长江技术经济学会版权所有</span>
                {/*<span>长江委宣传出版中心制作维护</span>*/}
              </li>
              <li><span>电话/传真：027-82926221</span><span>邮箱：office@cjxh.org.cn</span><span>地址：湖北省武汉市江岸区解放大道1863号</span></li>
            </ul>
          </span>
        </div>
        {/* <Modal
          title="温馨提示"
          footer={null}
          visible={this.state.remiderVisible}
          onCancel={this.hideReminder} >
          <div style={{marginBottom: "15px", lineHeight: 1.7}}>
            <span style={{color: "#FF0000", fontSize: "16px"}}>单位会员登录：<br /></span>
            <span >1. 单位会员用户名为18位统一社会信用代码或组织机构代码（注意：组织机构代码证中的“-”也必须输入），初始密码为1，请在登录后及时修改密码。<br />2. 统一社会信用代码的9-17位即为贵单位原组织机构代码。<br /></span>
            <span style={{color: "#FF0000", fontSize: "16px"}}>专家及个人会员登录：<br /></span>
            <span >会员用户名为个人18位身份证号，初始密码为1，请在登录后及时修改密码。<br /></span>
            <span style={{color: "#FF0000", fontSize: "16px"}}>入会申请表：<br /></span>
            <span >如果申请入会时没有打印申请表，请登录会员中心，单位点击“单位会员信息”、个人点击“个人会员信息”，下载并打印入会申请表。</span>
          </div>
          <div style={{paddingTop: "15px", lineHeight: 1.7, borderTop: "1px dashed #333"}}>
            <span>如有问题，请联系会员管理部门。<br />联系人: 方择：027-82829065/17612730587<br /><span>杨恩：027-82926221/15827296951</span><br />会费确认及票据查询：027-82926221<br />传真号码：027-82926221</span>
          </div>
        </Modal> */}
      </div >
    )
  }
}

export default Login
