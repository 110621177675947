import React from 'react'
import { Modal } from 'antd'
import './Unithelp.scss'

class Unithelp extends React.Component {

  // 关闭
  handleCancel = () => {
    const handleCancel = this.props.handleCancel;
    handleCancel()
  };

  render() {
    return (
      <Modal
        width={800}
        destroyOnClose
        centered
        title='组织成员-帮助文档'
        visible={this.props.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div style={{width: "100%"}}>
          <p className="paragraph">
            <span className="paragraphTitle">1、单位会员条件：</span><br />
            <span className="paragraphContent">（1）拥护本学会的章程；</span><br />
            <span className="paragraphContent">（2）有加入本学会的意愿；</span><br />
            <span className="paragraphContent">（3）在本学会的业务领域内具有一定的影响；</span><br />
            <span className="paragraphContent">（4）愿意积极参加本学会活动。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">2、单位会员权利：</span><br />
            <span className="paragraphContent">（1）具有本学会的选举权、被选举权和表决权。</span><br />
            <span className="paragraphContent">（2）对本学会的工作有批评建议权和监督权。</span><br />
            <span className="paragraphContent">（3）参加本学会所举办的活动。</span><br />
            <span className="paragraphContent">（4）得到本学会的各种信息、学会刊物、学术资料和本会服务的优先权。</span><br />
            <span className="paragraphContent">（5）入会自愿，退会自由。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">3、单位会员义务：</span><br />
            <span className="paragraphContent">（1）遵守本学会章程。</span><br />
            <span className="paragraphContent">（2）执行本学会的决议。</span><br />
            <span className="paragraphContent">（3）维护本学会的合法权益。</span><br />
            <span className="paragraphContent">（4）完成本学会所委托的工作。</span><br />
            <span className="paragraphContent">（5）按照规定缴纳会费。</span><br />
            <span className="paragraphContent">（6）向本学会反映情况，提供有关信息资料。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">联系人：</span><br />
            {/* <span className="paragraphContent"><span className="contactPeson">方&nbsp;&nbsp;&nbsp;&nbsp;择</span>027-82829065/17612730587</span><br /> */}
            {/* <span className="paragraphContent"><span className="contactPeson">杨恩</span>027-82926221/15827296951</span><br /> */}
            <span className="paragraphContent"><span className="contactPeson">王黎明</span>027-82926221/18994903640</span><br />
          </p>
        </div>
      </Modal>
    )
  }
}

export default Unithelp
