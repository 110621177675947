import React from 'react'
import './expertnotice.scss'

class Expertnotice extends React.Component {
  render() {
    return (
      <div className="expertNotice">
        <div className="contentOneHeader"><span>《专家须知》</span></div>
        <p className="contentOneOne">
          <span className="paragraphContent">长江科学技术奖是长江技术经济学会设立的科学技术奖项，秉承为流域经济社会发展服务的宗旨，坚持长江地域特色与长江大保护时代特征，鼓励流域治理与保护重大科技创新，侧重体现长江保护特色，兼顾基础研究与应用基础科研，重点奖励在流域自然资源、水利、能源、交通、生态环境等相关领域取得的创新性科技成果、为流域生态环境保护和高质量发展做出突出贡献的组织和个人，发挥“创新、协调、绿色、开放、共享”发展理念在长江经济带高质量发展中的引领作用。为提高长江科学技术奖质量，长江技术经济学会邀请您为奖励评审专家，建立评审专家库，每年根据需要随机抽取专家组建评审专家委员会，具体负责奖励评审工作。</span><br />
          <span className="paragraphTitle">一、专家条件</span><br />
          <span className="paragraphContent">1. 工作认真负责，具有良好的科学精神和职业道德；</span><br />
          <span className="paragraphContent">2. 年龄不超过65岁，具有教授、研究员或同等级别技术职称的一线科技工作者；</span><br />
          <span className="paragraphContent">3. 具有科研工作经历、现任正处级职务以上（含正处级）的科技管理人员；</span><br />
          <span className="paragraphContent">4. 创新型企业与高新技术企业技术负责人或技术骨干可以不受技术职称限制。</span><br />
          <span className="paragraphTitle">二、专家专业范围</span><br />
          <span className="paragraphContent">包括但不限于地球科学、水利工程、环境科学技术、交通运输工程、能源科学技术等相关学科领域。</span>
          {/* <span className="paragraphContent">请各专委会于2021年2月5日前将专家推荐表（格式见附件）签字盖章后报送长江技术经济学会。</span> */}
        </p>
      </div>
    )
  }
}

export default Expertnotice
