import axios from 'axios'
// import { getToken } from './auth'
import { message } from 'antd'
import { loginOut } from './auth'
import { notification } from "antd"
// Modal
// var modal = false;

// 设定请求超时时间
axios.defaults.timeout = 120000;
axios.defaults.withCredentials = false
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// 请求拦截添加token值
// axios.interceptors.request.use(config => {
//   return config
// }, err => {
//   message.error('请求超时');
//   return Promise.reject().catch((e) => { });
// })

// response 拦截器，根据需求要或者不要
axios.interceptors.response.use(response => {
  //后端正常返回
  // console.log(111, response)
  return response;
}, (error) => {
  // 返回状态码不为200时候的错误处理
  // console.log(222, error, error.toString())
  if (error.toString().indexOf("Network Error") > 0) {
    message.error("登录失效");
    // loginOut()
  } else if (error.response) {
    // console.log(333, error.response)
    if (302 === error.response.status) {
      message.error("登录失效");
      // loginOut()
    } else if (400 === error.response.status) {
      message.error("系统异常");
    }
  } else {
    return Promise.reject(error);
  }
})

// get请求
export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${url}`,
    params: params
  })
}

// post请求
export const postRequest = (url, params, type) => {
  if (type === 'blob') {
    return axios({
      method: 'post',
      url: `${url}`,
      data: params,
      responseType: 'blob'
    })
  } else if (type === 'upload') {
    return axios({
      method: 'post',
      url: `${url}`,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } else if (type !== 'json') {
    return axios({
      method: 'post',
      url: `${url}`,
      data: params
    })
  } else {
    return axios({
      method: 'post',
      url: `${url}`,
      data: params,
      transformRequest: [function (data) {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}

// put请求
export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${url}`,
    data: params
  })
}

// delete请求
export const deleteRequest = (url) => {
  return axios({
    method: 'delete',
    url: `${url}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

//获取文件信息
export const xhrPostRequest = (url, params) => {
  var xhr = new XMLHttpRequest(); // ⽤这种原⽣请求下载后端返回的⼆进制流打开就不会出现空⽩
  xhr.open("GET", url, true);
  // xhr.responseType = "blob";
  xhr.onload = function () {
    let response = this.response ? JSON.parse(this.response) : null
    if (response && response.code === 20000) {
      window.open(response.data.fileUrl)
    } else {
      notification.warning({
        message: '下载失败',
      });
    }
    // const url = window.URL.createObjectURL(this.response);
    // const link = document.createElement("a");
    // link.style.display = "none";
    // link.href = url;
    // link.setAttribute("download", params.name);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  xhr.setRequestHeader("AccessToken", localStorage.getItem('token'))
  xhr.send();
}
export const ctesXhrGetRequest = (url) => {
  var xhr = new XMLHttpRequest(); // ⽤这种原⽣请求下载后端返回的⼆进制流打开就不会出现空⽩
  xhr.open("GET", url, true);;
  xhr.onload = function () {
    let response = this.response ? JSON.parse(this.response) : null
    if (response && response.code === 200) {
      window.open(response.data.fileUrl)
    } else {
      notification.warning({
        message: '下载失败',
      });
    }
  };
  xhr.send();
}


// 下载文件流
export const xhrGetRequest = (url, name) => {
  var xhr = new XMLHttpRequest(); // ⽤这种原⽣请求下载后端返回的⼆进制流打开就不会出现空⽩
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    let url = window.URL.createObjectURL(this.response);
    let a = document.createElement('a');
    let filename = name;
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click()
  };
  xhr.setRequestHeader("AccessToken", localStorage.getItem('token'))
  xhr.send()
}