import React from 'react'

class error404 extends React.Component {
  render() {
    return (
      <div>我是404页面</div>
    )
  }
}

export default error404
