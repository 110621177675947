import React from 'react'
import {Form, Select, Input, Radio, DatePicker, Steps, Checkbox, Button, message, Modal, List, Upload} from 'antd'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './RegisterPerson.scss'
import {
  uploadPersonData,
  judgeAccount,
  regInsertOrg,
  getOrgByName,
  multipleUploadFile,
  delFileInDBAndLocal
} from '../../service/api'
import logo from '../../assets/images/logo.png'
import Constitution from '../../components/constitution/constitution'
import Registerwait from '../../components/registerWait/registerwait'
import qs from 'qs'
// import {postRequest} from "../../utils/request";
import { DebounceSelect } from "../../components/select/DebounceSelect";
import { checkPassword, getBirthdayFromIdCard, checkIdCard, getDeviceType } from '../../utils/stringUtil'
import moment from 'moment';
import {UploadOutlined} from "@ant-design/icons";

const { Step } = Steps;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    }
  }
};

const apliction = '我自愿加入长江技术经济学会，拥护学会章程，维护学会利益，执行学会决议，积极履行学会义务。本人承诺入会资料真实有效，如有情况，自愿承担相关责任。';
const party = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众'];
const title = ['正高级职称', '副高级职称', '中级职称', '初级职称', '无', '其他'];
const memberTagOption = ['理事长', '常务副理事长', '副理事长', '秘书长', '常务理事', '副秘书长', '理事', '会员'];
const qualification = ['博士后', '博士研究生', '硕士研究生', '本科', '专科'];
// const IDCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const SpaceReg = /^(?!(\s+$))/;//纯空格

class RegisterPerson extends React.Component {

  formRef = React.createRef();

  state = {
    currentStep: 0,
    showContentOne: true,
    showContentTwo: false,
    showContentThree: false,
    checkOneValue: false,
    checkTwoValue: false,
    loading: false,
    registerData: {
      promiseWord: apliction,
      sex: 1,
      memberTag1: '会员',//隐藏学会职务默认为会员
    },
    isUpdate: false,
    orgObj: {
      label: '',
      value: ''
    },
    addOrgName: "",
    addOrgButtonFlag: false,
    showAddOrgModalFlag: false,
    nowZc: "",
    deviceType: "",//设备类型
    fjList: [],//附件数组
  };

  componentDidMount() {
    let url = this.props.location.search;
    // console.log(url, 11111)
    if (url) {
      let parameter = url.split('?')[1];
      // console.log(parameter, 11111)
      let parameterValue = parameter.split('=')[1];
      // console.log(parameterValue, 11111);
      // 获取此账户的值，并重新设定registerData，isUpdate改为true
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1,
        deviceType: getDeviceType()
      })
    } else {
      this.setState({
        deviceType: getDeviceType()
      })
    }

  }

  handleOrgChange = (value) => {
    // console.log(`selected ：`+JSON.stringify(value));
    this.setState({
      orgObj: value
    })
  };

  showAddOrgModal = () => {
    this.setState({
      showAddOrgModalFlag: true
    })
  };

  handleAddOrgOk = () => {

    const param = qs.stringify({ 'orgName': this.state.addOrgName });
    regInsertOrg(param).then(res => {
      let data = res.data;
      if (data.code === 200) {
        message.success('新增成功，请重新搜索单位！');
        this.setState({
          showAddOrgModalFlag: false
        })
        // console.log(44, data.data);
      } else if (data.code === 4010) {
        //账号已存在
        return message.error('此单位名称已存在，请核实后再填写！')
      }
    }).catch(
      () => { return message.error('新增单位异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！') }
    );
  };

  handleAddOrgCancel = () => {
    this.setState({
      showAddOrgModalFlag: false
    })
  };

  onOrgNameChange = e => {
    this.setState({
      addOrgName: e.target.value.trim()
    })
  };


  fetchOrgList = (orgname) => {
    // console.log('fetching user', orgname);
    let param = { 'name': orgname };
    return getOrgByName(param).then(res => {
      if (res.data.data.length < 1) {
        this.setState({
          addOrgButtonFlag: true
        });
      } else {
        this.setState({
          addOrgButtonFlag: false
        });
      }
      return res.data.data.map(org => ({
        label: org.orgName,
        value: org.orgId
      }))
    })

  };

  checkAccount = e => {
    let account = e.target.value;
    if (null === account || typeof account === undefined || "undefined" === account || "" === account) return;
    // const param=JSON.stringify({account:account,type:'expertTable'})
    const param = qs.stringify({ 'account': account, 'type': 'user' });
    judgeAccount(param).then(res => {
      let data = res.data;
      if (data.code === 2008) {
        //账号已存在
        return message.error({
          content: '此身份证号已注册，请核实后再填写！',
          style: {
            marginTop: '40vh'
          }
        })
      }
    })
  };

  // 长江技术经济协会章程确认
  checkOne = e => {
    // console.log(e, 111)
    let checkOneValue = e.target.checked;
    this.setState({
      checkOneValue: checkOneValue
    })
  };

  queryOne = () => {
    let dom = document.getElementsByClassName('personContent')[0];
    if (this.state.checkOneValue) {
      dom.scrollTop = 0;
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1,
      })
    }
  };

  // 个人表格
  // checkTwo = e => {
  //   // console.log(e, 111)
  //   let checkTwoValue = e.target.checked
  //   this.setState({
  //     checkTwoValue: checkTwoValue
  //   })
  // }

  // queryTwo = () => {
  //   if(this.state.checkTwoValue) {
  //     this.setState({
  //       showContentOne: false,
  //       showContentTwo: false,
  //       showContenThree: true,
  //       currentStep: 2
  //     })
  //   }
  // }

  // 生日赋值
  timeChange = (date, dateString) => {
    // console.log(date, 1111)
    // console.log(dateString, 22222)
    this.setState({
      birthday: dateString
    })
  };

  // 身份证号发生变化时自动复制出生日期
  onCardIdChange = () => {
    let formCurrent = this.formRef.current;
    formCurrent.setFieldsValue({
      birthday: getBirthdayFromIdCard(formCurrent.getFieldValue('idCard'))
    })
    this.setState({
      birthday: moment(formCurrent.getFieldValue('birthday')).format('YYYY-MM-DD')
    })
  }

  // 职称为其他时需要自己输入
  zcCheck = value => {
    this.setState({
      nowZc: value
    })
  };

  // 提交
  onFinish = e => {
    this.setState({
      loading: true
    }, () => {
      let birthday = this.state.birthday;
      let allData = e;
      //隐藏字段未定义处理为空字符
      if (!allData.city) {
        allData.city = '';
      }
      if (!allData.tel) {
        allData.tel = '';
      }
      if (!allData.weChat) {
        allData.weChat = '';
      }
      if (!allData.degree) {
        allData.degree = '';
      }
      if (!allData.major2) {
        allData.major2 = '';
      }
      allData.birthday = birthday;
      allData.promiseWord = apliction;
      if (allData.sex === 1) {
        allData.sex = '男'
      } else {
        allData.sex = '女'
      }
      if (allData.zc == "其他") {
        allData.zc = allData.otherZc ? allData.otherZc : ""
      }
      let companyAttach = '';
      if (this.state.fjList.length > 0) {
        let attachArr = [];
        this.state.fjList.forEach(item => {
          attachArr.push(item.uid);
        });
        companyAttach = JSON.stringify(attachArr);
      }
      allData.companyAttach = companyAttach;
      allData.address = allData.address?.trim();
      allData.city = allData.city?.trim();
      allData.degree = allData.degree?.trim();
      allData.graduatedFrom = allData.graduatedFrom?.trim();
      allData.major1 = allData.major1?.trim();
      allData.major2 = allData.major2?.trim();
      allData.name = allData.name?.trim();
      allData.national = allData.national?.trim();
      allData.recommendUnit = allData.recommendUnit?.trim();
      allData.researchField = allData.researchField?.trim();
      allData.tel = allData.tel?.trim();
      allData.unitName = allData.unitName?.trim();
      allData.weChat = allData.weChat?.trim();
      allData.zw = allData.zw?.trim();
      allData.vId = `person_${new Date().getTime()}${allData.mobileTel}`;
      allData.confirmStatus = '0';
      allData.company = this.state.orgObj.label;
      allData.orgId = this.state.orgObj.value;
      uploadPersonData(allData).then(res => {
        this.setState({
          loading: false
        });
        let data = res.data;
        if (data.code === 200) {
          message.success('注册成功，请等待审核！');
          this.setState({
            showContentTwo: false,
            showContentThree: true,
            currentStep: 2
          })
        } else if (data.code === 2008) {
          return message.error('此身份证号已注册，请核实后再填写！')
        } else {
          return message.error('注册失败，请重新注册！')
        }
      }).catch((error) => {
        this.setState({
          loading: false
        });
        // console.log("catch", error);
        return message.error('注册异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！')
      })
    })
  };

  // 返回登录页
  backLogin = () => {
    // console.log('返回', 22222)
    this.props.history.push('/login')
  };

  //附件上传
  //文件上传完成后执行的操作
  fjHandleChange = info => {
    console.log(111, info)
    let fjListExtra = [...info.fileList];
    fjListExtra = fjListExtra.map(file => {
      if (file.response) {
        file.uid = file.response.uid;
        file.url = file.response.url;
        file.name = file.response.fileName;
      }
      return file;
    });

    this.setState({
      fjList: fjListExtra
    });
  };
  fjRemove = file => {
    const fjListExtra = this.state.fjList.filter(item => item.url !== file.url);
    this.setState({
      fjList: fjListExtra
    });

    const param = qs.stringify({
      'id': file.uid,
      'fileUrl': file.url
    });
    delFileInDBAndLocal(param).then().catch();
  };

  render() {

    return (
      <div className={`${this.state.deviceType == 'Browser' ? 'registerPerson' : 'registerPerson mobile'}`}>
        <div className="loginLog">
          <img className="logoImage" src={logo} alt="logo" />
        </div>
        <div className="personBox">
          <div className="personStep">
            <Steps current={this.state.currentStep}>
              <Step title="第一步" description="认真阅读学会章程" />
              <Step title="第二步" description="填写个人会员注册信息" />
              <Step title="第三步" description="注册温馨提示" />
            </Steps>
          </div>
          <div className="personContent">
            <div className={`${this.state.showContentOne ? '' : 'hideContent'}`}>
              <Constitution />
              <div className="constitutionConfirm">
                <div className="checkBox">
                  <Checkbox checked={this.state.checkOneValue} onChange={e => this.checkOne(e)}>本人已阅读，并同意遵守长江技术经济学会章程</Checkbox>
                </div>
                <Button style={{ width: "150px" }} className={`${this.state.checkOneValue ? 'checked' : 'noChecked'}`} onClick={this.queryOne}>下一步</Button>
              </div>
            </div>
            <div className={`${this.state.showContentTwo ? '' : 'hideContent'}`}>
              {/* <Personautonomy /> */}
              <div className="tableConfirm">
                <Form
                  ref={this.formRef}
                  {...formItemLayout}
                  name="register"
                  initialValues={this.state.registerData}
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <div className="baseTitle"><span>个人基本信息</span></div>
                  <Form.Item
                    name="name"
                    label="姓名"
                    rules={[
                      {
                        required: true,
                        message: '请填写姓名',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>


                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="memberTag1"
                    label="学会职务"
                  >
                    <Select placeholder="请选择学会职务">
                      {memberTagOption.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="sex"
                    label="性别"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="sex">
                      <Radio value={1}>男</Radio>
                      <Radio value={2}>女</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="city"
                    label="籍贯"
                  >
                    <Input placeholder="省市，例如：湖北省武汉市" />
                  </Form.Item>
                  <Form.Item
                    name="national"
                    label="民族"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="party"
                    label="政治面貌"
                    rules={[
                      {
                        required: true,
                        message: '请选择党派',
                      }
                    ]}
                  >
                    <Select placeholder="请选择党派">
                      {party.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="idCard"
                    label="身份证号"
                    onBlur={this.checkAccount}
                    rules={[
                      {
                        required: true,
                        message: '请填写身份证号'
                      },
                      // {
                      //   pattern: IDCardReg,
                      //   message: '请填写正确身份证号',
                      // },
                      {
                        validator: checkIdCard
                      }
                    ]}
                  >
                    <Input onChange={this.onCardIdChange} />
                  </Form.Item>
                  <Form.Item
                    name="zw"
                    label="职务"
                    rules={[
                      {
                        required: true,
                        message: '请输入职务',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="zc"
                    label="职称"
                    rules={[
                      {
                        required: true,
                        message: '请选择职称',
                      }
                    ]}
                  >
                    <Select placeholder="请选择职称" onChange={this.zcCheck}>
                      {title.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {this.state.nowZc == "其他" ?
                    <Form.Item
                      name="otherZc"
                      label="其他职称"
                      rules={[
                        {
                          required: true,
                          message: '请输入其他职称',
                        }
                      ]}
                    >
                      <Input style={{ width: 150 }} placeholder="若选其他，请填写" />
                    </Form.Item>
                    : null
                  }
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="birthday"
                    label="出生年月"
                  >
                    <DatePicker inputReadOnly locale={locale} onChange={this.timeChange} placeholder="请选择时间" />
                  </Form.Item>
                  <Form.Item
                    name="recommendUnit"
                    label="推荐单位"
                    rules={[
                      {
                        required: true,
                        message: '请输入推荐单位',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="baseTitle"><span>联系方式</span></div>
                  <Form.Item
                    name="company"
                    label="工作单位"
                    help="请先输入单位名称搜索选择对应单位，如未出现可新增单位"
                    rules={[
                      {
                        required: true,
                        message: '请填写工作单位',
                      }
                    ]}
                  >
                    {/*<Input />*/}
                    <DebounceSelect
                      value={this.state.orgObj}
                      placeholder="搜索单位"
                      fetchOptions={this.fetchOrgList}
                      onChange={this.handleOrgChange}
                      style={{
                        width: '100%',
                      }}
                    />

                  </Form.Item>
                  {this.state.addOrgButtonFlag ?
                    <Form.Item name="orgNameAdd" label=" ">
                      没有找到对应单位？试试<Button onClick={this.showAddOrgModal}>新增单位</Button>
                    </Form.Item> : ""
                  }

                  <Modal title="新增单位" visible={this.state.showAddOrgModalFlag}
                    onOk={this.handleAddOrgOk}
                    onCancel={this.handleAddOrgCancel}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Form.Item
                      className='unitName'
                      name="unitName"
                      rules={[
                        {
                          pattern: SpaceReg,
                          message: '输入内容不能为空'
                        }
                      ]}
                    >
                      <Input placeholder="输入单位名称" value={this.state.addOrgName} onChange={this.onOrgNameChange} />
                    </Form.Item>
                  </Modal>

                  <Form.Item
                    name="fjList"
                    label="所在单位推荐函"
                    rules={[
                      {
                        required: true,
                        message: '请上传所在单位推荐函',
                      }
                    ]}
                  >
                    <Upload
                        name="file"
                        multiple={true}
                        action={multipleUploadFile}
                        fileList={this.state.fjList}
                        data={{ type: 'mailPath', opt: 'saveDB' }}
                        onChange={this.fjHandleChange}
                        onRemove={this.fjRemove}
                    >
                      <Button icon={<UploadOutlined />}>上传</Button>
                    </Upload>
                    <a href='/opt/ctes_server/awards/outFile/所在单位推荐函V1.docx' target='__blank'> 推荐函模板下载</a>
                  </Form.Item>

                  <Form.Item
                    name="address"
                    label="通讯地址"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="mobileTel"
                    label="手机号码"
                    rules={[
                      {
                        required: true,
                        message: '请填写手机号码'
                      },
                      {
                        pattern: phoneReg,
                        message: '请填写正确手机号码'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="tel"
                    label="电话"
                  >
                    <Input placeholder="可以和手机同号" />
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="weChat"
                    label="微信号"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: 'email',
                        message: '输入不是有效电子邮箱',
                      },
                      {
                        required: true,
                        message: '请填写电子邮箱',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="baseTitle"><span>专业信息</span></div>
                  <Form.Item
                    name="education"
                    label="最高学历"
                    rules={[
                      {
                        required: this.state.deviceType == 'Browser' ? true : false,
                        message: '请选择最高学历',
                      }
                    ]}
                  >
                    <Select placeholder="请选择最高学历">
                      {qualification.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="degree"
                    label="最高学位"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="graduatedFrom"
                    label="毕业院校"
                    rules={[
                      {
                        required: this.state.deviceType == 'Browser' ? true : false,
                        message: '请填写毕业院校',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="major1"
                    label="专业"
                    rules={[
                      {
                        required: this.state.deviceType == 'Browser' ? true : false,
                        message: '请输入专业',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="major2"
                    label="专业2"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="researchField"
                    label="研究方向"
                  >
                    <Input placeholder="可填多个" />
                  </Form.Item>
                  <div className="baseTitle"><span>其他</span></div>
                  <Form.Item
                    name="pwd"
                    label="登录密码"
                    rules={[
                      {
                        validator: checkPassword
                      },
                      {
                        required: true,
                        message: '请填写登录密码!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="confirmPwd"
                    label="确认密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '请确认您的密码!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('pwd') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('两次密码不一致!');
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="promiseWord"
                    label="入会声明"
                  >
                    <Input.TextArea disabled rows={3}/>
                  </Form.Item>
                  <div className="buttonItem">
                    {this.state.isUpdate ? <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      更改提交
                    </Button> : <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      提交
                    </Button>}
                  </div>
                </Form>
              </div>
            </div>
            <div className={`${this.state.showContentThree ? '' : 'hideContent'}`}>
              <Registerwait />
              <div className="contentThreeButton">
                <Button className='registerBtn backBtn' type="primary" onClick={this.backLogin}>返回登录页</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">©2021 长江技术经济学会 版权所有</span>
        </div>
      </div>
    )
  }
}

export default RegisterPerson
