import React from 'react'
import { Modal } from 'antd'
import './Personhelp.scss'

class Personhelp extends React.Component {
  // 关闭
  handleCancel = () => {
    const handleCancel = this.props.handleCancel;
    handleCancel()
  };

  render() {
    return (
      <Modal
        width={800}
        destroyOnClose
        centered
        title='个人会员-帮助文档'
        visible={this.props.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div style={{width: "100%"}}>
          <p className="paragraph">
            <span className="paragraphTitle">1、个人会员权利：</span><br />
            <span className="paragraphContent">（1）具有本学会选举权、被选举权和表决权；</span><br />
            <span className="paragraphContent">（2）对本学会工作有批评建议权和监督权；</span><br />
            <span className="paragraphContent">（3）参加本学会举办的活动；</span><br />
            <span className="paragraphContent">（4）得到本学会各种信息、学会刊物、学术资料和个人会员服务优先权；</span><br />
            <span className="paragraphContent">（5）入会自愿，退会自由。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">2、个人会员义务：</span><br />
            <span className="paragraphContent">（1）遵守本学会章程；</span><br />
            <span className="paragraphContent">（2）执行本学会决议；</span><br />
            <span className="paragraphContent">（3）维护本学会合法权益；</span><br />
            <span className="paragraphContent">（4）完成本学会委托的工作；</span><br />
            <span className="paragraphContent">（5）向本学会反映情况，提供有关信息资料。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">3、其他注意事项：</span><br />
            <span className="paragraphContent">（1）长江技术经济学会个人会员暂不收取会费；</span><br />
            <span className="paragraphContent">（2）会员退会应书面或邮件通知本学会；</span><br />
            <span className="paragraphContent">（3）会员如有严重违反本学会章程的行为，经理事会或常务理事会讨论通过，予以除名。</span><br />
          </p>
          <p className="paragraph">
            <span className="paragraphTitle">联系人：</span><br />
            {/* <span className="paragraphContent"><span className="contactPeson">方&nbsp;&nbsp;&nbsp;&nbsp;择</span>027-82829065/17612730587</span><br /> */}
            {/* <span className="paragraphContent"><span className="contactPeson">杨恩</span>027-82926221/15827296951</span><br /> */}
            <span className="paragraphContent"><span className="contactPeson">王黎明</span>027-82926221/18994903640</span><br />
          </p>
        </div>
      </Modal>
    )
  }
}

export default Personhelp
