import 'core-js/es';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './index.css';
import App from './App';
import { mainRoutes } from './routes'
import reportWebVitals from './reportWebVitals';
// import Login from './pages/Login/Login'

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/adminpages" render={routeProps => <App {...routeProps} />}></Route>
      <Route path="/memberpages" render={routeProps => <App {...routeProps} />}></Route>
      {mainRoutes.map(route => {
        return <Route key={route.path} {...route}></Route>
      })}
      <Redirect to="/login"></Redirect>
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
