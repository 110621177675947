import React from 'react'
import './registerwait.scss'

class Registerwait extends React.Component {

  render() {
    return (
      <div className="contentThree">
        <p className="contentThreeOne">
          <span>谢谢您的注册！</span><br />
          {/* <span>注册后请等待审核。</span><br /> */}
          <span>长江技术经济学会期待您的加入！</span>
        </p>
        <p className="contentThreeTwo">
          <span>如有问题，请咨询以下联系人：</span><br />
          {/* <span>方择：027-82829065/17612730587</span><br /> */}
          {/* <span>杨恩：027-82926221/15827296951</span> */}
          <span>王黎明：027-82926221/18994903640</span>
        </p>
      </div>
    )
  }
}

export default Registerwait
