import moment from 'moment';
import { JSEncrypt } from 'jsencrypt'

// 公钥
const PUB_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAohMRTo1UbmSXBl9gl4+BdQP7j5xB4PuDaM2UoIyX7t0X7djexOhzQ2z/ZZQRHLxw9y0a0MVSmGDQDUj6+yQKcgKgeZjsTajNsa0ceNhH64y4bDj8O9EWHqEwIAiDur3o9K0WFoXgIXeXS0G4puYNauu4hFEDM8FQE/BZ8C7OX2NLdCqqX6JIhfmq8pOlloLaDllS58XLtR8oIfQnBiwTIaxqYALUf/4Sh8DzN4mviIvnO7fcGFBTTw0aNTroRysbzRJldrlU77LtHgZAj019srcIEdZJmeA5nUweqyC+RWaDxDdh/WF9HNCfeLLtffTj7j4wQlRFvQylrejTVCaA4wIDAQAB';
// 私钥
const PRIV_KEY = 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCiExFOjVRuZJcGX2CXj4F1A/uPnEHg+4NozZSgjJfu3Rft2N7E6HNDbP9llBEcvHD3LRrQxVKYYNANSPr7JApyAqB5mOxNqM2xrRx42EfrjLhsOPw70RYeoTAgCIO6vej0rRYWheAhd5dLQbim5g1q67iEUQMzwVAT8FnwLs5fY0t0KqpfokiF+aryk6WWgtoOWVLnxcu1Hygh9CcGLBMhrGpgAtR//hKHwPM3ia+Ii+c7t9wYUFNPDRo1OuhHKxvNEmV2uVTvsu0eBkCPTX2ytwgR1kmZ4DmdTB6rIL5FZoPEN2H9YX0c0J94su199OPuPjBCVEW9DKWt6NNUJoDjAgMBAAECggEAJARgwDggHzgzZ7MiYAwAFOUBrnRnH3xFQ7EL6nHUAb0YaD7dopyVoNJEH2q8MsqdwBBB+Usgg8Rf99PUEqN7vsJerycQvGFa9LUnsqM9uFbZVROE2jYo2D2x2CyMOZ+ieV1Nn0u49zcAZDUCt/Q/3xbrNlxafdHj3cp38iw0qHWn5JD4R3cBuZTvvlgrkPYXPHPYXJQ6y5bDDonb5FaDfdZRNuEdY73i2VT//DeivgZ+F4fj2uuxdTxW8dYmgXBrjjHAni6c06H7LCg8LTdam8jojVhLFvTnmwZALi0F2AMjZA4CL3bloHKjJwO6qwbKZdJ+WIeFsPsVZeoeQPQ24QKBgQD79ALiCDuwHZitEG4b1CPm2WzA3BMA0yiU+RBdFiPFbUqq8b1fMOcGwJhHw6gfV75t1BY2tso0++on9WPM6j6VPIGrbVhfGRmnCCBQ1HM26hRBAUYVhqi6VAdxrJbatzAkqjT5fMLbPRWDxWI+nhWNSHVulqEVP7AhZybQqZsG9wKBgQCkrX2L3erCBWfJ3cn14G9CMNy8gXU2LfD5TD1kTPDAp+e0pviIsxWGIbikZnVft7TdEb9l6V8W7QOnrqFQPluZOKeHNBIW6jx8L9pmUWU2fKOLiuNxeBy79xz24gqydfAkVhmkPYZJJ19cN2CvLXgrC13zv7SgfDkJ2TkK+qe+dQKBgCWz0wad8sic9S7YqZFygljWKmcFx7Y04vq/9onneTze6PCBsjPfboLdKSx8A9mE2NM4jIZtff7Fv7lxCBku6GSOpFzRxQqeB++t0KAn2oHaL2O23BT2jKMtVdvV1tEt3n9uFPVMjSAfmOuaCuSR5Wm58RC2a65xVFr7Q5yv9KnrAoGAImtorE1L/hWnJdhHmC660njoCjZ8y0A698BprIcwYvQ1R9sGY3wa6ZguwZ3wtnrtQK+u85wdlesgMnIboWwHNTVUPrwxc7ytu788xNoKb6MJg+cZtuPGDbuOwENqvPZNpSqh3WRGpw+lrN6eRL8aONT9ZC8Tj9+nBFn0xEizuukCgYA4sBJOJvdwxLXGkf87WWXjgpaVudLwfWquOy67hoM2Mn9Ilf3Wbl99rFJLq+QcHv9jllIza56E9ooVLYnMiVDzZNRceewYhCIExRppY7/sn/qeJHsymGU2S749zxNbwii6N1XmWncrr9kshP2sB3Cn5eUlr5YE8mQ++WJLD8Rhnw==';
//加密
export function encrypt(text) {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUB_KEY);
    let encrypted = encrypt.encrypt(text);
    console.log('encrypt------->' + encrypted);
    return encrypted
}

//解密
export function decrypt(text) {
    let decrypt = new JSEncrypt();
    decrypt.setPrivateKey(PRIV_KEY);
    let decrypted = decrypt.decrypt(text);
    // console.log('decrypted------->' + decrypted);
    return decrypted
}


//获取yyyy-MM-dd中的年份后2位
export function getVidYear(year) {
    let result = "XX";
    if (year.length > 4) {
        result = year.substring(2, 4);
    }
    return result;
}
//补齐需要中前面缺少的零，如98补齐为00098
export function SupplementNum(num, bqLength) {
    let result = "";
    num = num + "";
    if (num.length < bqLength) {
        for (let i = 0; i < bqLength - num.length; i++) {
            result += "0";
        }
        result += num;
    } else {
        result = num;
    }

    return result;
}


export function isEmpty(str) {
    return null === str || str === undefined || "" === str || typeof (str) == "undefined";;
}

//设备检查
export function getDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
    }

    if (/Windows Phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    return 'Browser';
}

//自定义校验规则
/*
 * 复杂密码校验
 */
export async function checkPassword(rule, value) {
    var reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
    if (!isEmpty(value)) {
        if (!reg.test(value)) {
            return Promise.reject(new Error('密码至少八位且包含大小写字母、数字、特殊字符!'))
        }
    }
};

/*
 * 任意号码格式
 */
export async function checkMobileOrPhone(rule, value) {
    var reg1 = new RegExp("^1[34578][0-9]{9}$", 'i');
    var reg2 = /^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)|(^0\d2-?\d{8}$)|(^0\d3-?\d{7}$)$/;
    if (!isEmpty(value)) {
        if (!reg1.test(value) && !reg2.test(value)) {
            return Promise.reject(new Error('号码不规范!'))
        }
    }
};

/*
* 手机号码格式
*/
export async function checkMobile(rule, value) {
    const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
    if (!isEmpty(value) && value.indexOf('admin') === -1&& value.indexOf('ckyAdmin') === -1 && value.indexOf('jbr') == -1 && value.indexOf('fzr') === -1 && value.indexOf('zyh') === -1) {
        if (!reg.test(value)) {
            return Promise.reject(new Error('请输入正确的手机号码!'))
        }
    }
};

/*
 * 固定电话号码格式
 * 因为固定电话格式比较复杂，情况比较多，主要验证了以下类型
 * 如：010-12345678、0912-1234567、(010)-12345678、(0912)1234567、(010)12345678、(0912)-1234567、01012345678、09121234567
 */
export async function checkPhone(rule, value) {
    var reg = /^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)|(^0\d2-?\d{8}$)|(^0\d3-?\d{7}$)$/;
    if (!isEmpty(value)) {
        if (!reg.test(value)) {
            return Promise.reject(new Error('号码不规范!'))
        }
    }
};

/*
 * Email邮箱
 * 如：zhangsan@163.com、li-si@236.net、wan_gwu999@SEED.NET.TW
 */
export async function checkEmail(rule, value) {
    var reg = /^([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+(\.[a-zA-Z]{2,3})+$/;
    if (!isEmpty(value)) {
        if (!reg.test(value)) {
            return Promise.reject(new Error('邮箱不规范!'))
        }
    }
};

/*
 * 邮政编码
 */
export async function checkPostalCode(rule, value) {
    var reg = /^\d{6}$/;
    if (!isEmpty(value)) {
        if (!reg.test(value)) {
            return Promise.reject(new Error('邮政编码不规范!'))
        }
    }
};

// 通过身份证获取生日
export function getBirthdayFromIdCard(idCard) {
    var birthday = '';
    if (idCard) {
        if (idCard.length == 15) {
            birthday = "19" + idCard.substr(6, 4);
        } else if (idCard.length == 18) {
            birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }
    return birthday ? moment(birthday, 'YYYY-MM-DD') : '';
};

/*
 * 身份证15位编码规则：dddddd yymmdd xx p
 * dddddd：6位地区编码
 * yymmdd: 出生年(两位年)月日，如：910215
 * xx: 顺序编码，系统产生，无法确定
 * p: 性别，奇数为男，偶数为女
 *
 * 身份证18位编码规则：dddddd yyyymmdd xxx y
 * dddddd：6位地区编码
 * yyyymmdd: 出生年(四位年)月日，如：19910215
 * xxx：顺序编码，系统产生，无法确定，奇数为男，偶数为女
 * y: 校验码，该位数值可通过前17位计算获得
 *
 * 前17位号码加权因子为 Wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ]
 * 验证位 Y = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ]
 * 如果验证码恰好是10，为了保证身份证是十八位，那么第十八位将用X来代替
 * 校验位计算公式：Y_P = mod( ∑(Ai×Wi),11 )
 * i为身份证号码1...17 位; Y_P为校验码Y所在校验码数组位置
 */
export async function checkIdCard(rule, value) {
    //校验格式（15或18）
    var reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    if (!isEmpty(value)) {
        if (reg.test(value)) {
            //检验准确性
            if (value.length == 18) {
                var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //将前17位加权因子保存在数组里
                var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
                var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
                for (var i = 0; i < 17; i++) {
                    idCardWiSum += value.substring(i, i + 1) * idCardWi[i];
                }
                var idCardMod = idCardWiSum % 11;//计算出校验码所在数组的位置
                var idCardLast = value.substring(17);//得到最后一位身份证号码
                //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
                if (idCardMod == 2) {
                    if (idCardLast == "X" || idCardLast == "x") {
                    } else {
                        return Promise.reject(new Error('身份证格式不规范!'))
                    }
                } else {
                    //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
                    if (idCardLast == idCardY[idCardMod]) {
                    } else {
                        return Promise.reject(new Error('身份证格式不规范!'))
                    }
                }
            }
        } else {
            return Promise.reject(new Error('身份证格式不规范!'))
        }

        // 根据身份证，计算年龄
        let len = (value + "").length;
        if (len == 0) {
            return 0;
        } else {
            if ((len != 15) && (len != 18)) {
                return 0;
            }
        }

        let strBirthday = "";
        if (len == 18) {
            strBirthday = value.substr(6, 4) + "/" + value.substr(10, 2) + "/" + value.substr(12, 2);
        }
        if (len == 15) {
            strBirthday = "19" + value.substr(6, 2) + "/" + value.substr(8, 2) + "/" + value.substr(10, 2);
        }
        // 时间字符串里，必须是 "/"
        let birthDate = new Date(strBirthday);
        let nowDateTime = new Date();
        let age = nowDateTime.getFullYear() - birthDate.getFullYear();
        // 再考虑月、天的因素; .getMonth()获取的是从0开始的，这里进行比较，不需要加1
        if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            return Promise.reject(new Error('不满足18周岁以上的年龄限制，无法注册'))
        }
    }
};