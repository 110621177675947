import Loadable from 'react-loadable'
import { message } from 'antd'

// 按需加载组件
export default function withLoadable (comp) {
  return Loadable({
    loader: comp,
    loading: (props)=>{
      if (props.error) {
        message.error('加载错误，请刷新')
        return false
      } else if (props.timedOut) {
        message.error('加载超时，请刷新')
        return false
      } else if (props.pastDelay) {
        return false
      } else {
        return null
      }
    },
    timeout: 10000
  })
}