import React from 'react'
import {
  Form,
  Select,
  Input,
  Radio,
  DatePicker,
  Steps,
  Checkbox,
  Button,
  Upload,
  message,
  Modal
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { uploadExpertData, judgeAccount, getOrgByName, regInsertOrg } from '../../service/api'
import './RegisterExpert.scss'
import logo from '../../assets/images/logo.png'
import Expertnotice from '../../components/expertNotice/expertnotice'
import Registerwait from '../../components/registerWait/registerwait'
import { DebounceSelect } from '../../components/select/DebounceSelect'
import qs from 'qs'
import { checkPassword, getBirthdayFromIdCard, checkIdCard } from '../../utils/stringUtil'
import moment from 'moment';
// import Constitution from '../../components/constitution/constitution'
// import Unitautonomy from '../../components/unitAutonomy/unitautonomy'

const { Step } = Steps;
const { Option } = Select;

const apliction = '本人承诺入会资料真实有效，并不以学会专家身份对外开展相关工作。如有情况，自愿承担相应责任。';
const politic = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众'];
const technicalTitle = ['正高级职称', '副高级职称', '中级职称', '初级职称', '其他'];
const subjectOption = ['地球科学', '水利工程', '环境科学技术', '交通运输工程', '能源科学技术', '其他'];
const qualification = ['博士后', '博士研究生', '硕士研究生', '本科', '专科'];
const memberTagOption = ['理事长', '常务副理事长', '副理事长', '秘书长', '常务理事', '副秘书长', '理事', '会员', '无'];
const isRecommend = ['流域综合治理开发专业委员会', '流域能源专业委员会', '流域综合交通专业委员会', '流域经济发展专业委员会', '流域环境保护专业委员会',
  '流域河湖保护与修复专业委员会', '长江三角洲保护与发展专业委员会', '青年工作委员会', '科普工作委员会', '否'];
// const IDCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const SpaceReg = /^(?!(\s+$))/;//纯空格

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    }
  }
};

function beforeUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('照片必须小于10MB!');
  }
  return isLt2M;
}

class Registerexpert extends React.Component {

  formRef = React.createRef();

  state = {
    currentStep: 0,
    showContentOne: true,
    showContentTwo: false,
    showContentThree: false,
    checkOneValue: false,
    checkForeignValue: false,
    loading: false,
    fileList: [],
    uploadImage: null,
    isUploadImg: false,
    academicianValue: 1,
    otherAcademician: '',
    isShowPwd: true,
    registerData: {
      promiseWord: apliction,
      sex: 1,
      isAcademician: 3,
      isMember: 1,
      memberTag1: '会员'//隐藏学会职务当是否同时成为会员为是默认为会员，否则为无
    },
    isUpdate: false,
    orgObj: {
      label: '',
      value: ''
    },
    addOrgName: "",
    addOrgButtonFlag: false,
    showAddOrgModalFlag: false,
    nowZc: ""
  };

  componentDidMount() {
    let url = this.props.location.search;
    if (url) {
      let parameter = url.split('?')[1];
      let parameterValue = parameter.split('=')[1];
      // 获取此账户的值，并重新设定registerData，isUpdate改为true
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1
      })
    }
  }

  handleOrgChange = (value) => {
    // console.log(`selected ：`+JSON.stringify(value));
    this.setState({
      orgObj: value
    })
  };

  showAddOrgModal = () => {
    this.setState({
      showAddOrgModalFlag: true
    })
  };

  handleAddOrgOk = () => {

    const param = qs.stringify({ 'orgName': this.state.addOrgName });
    regInsertOrg(param).then(res => {
      let data = res.data;
      if (data.code === 200) {
        message.success('新增成功，请重新搜索单位！');
        this.setState({
          showAddOrgModalFlag: false
        })
      } else if (data.code === 4010) {
        //账号已存在
        return message.error('此单位名称已存在，请核实后再填写！')
      }
    }).catch(
      () => { return message.error('新增单位异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！') }
    );
  };

  handleAddOrgCancel = () => {
    this.setState({
      showAddOrgModalFlag: false
    })
  };

  onOrgNameChange = e => {
    this.setState({
      addOrgName: e.target.value.trim()
    })
  };


  fetchOrgList = (orgname) => {
    // console.log('fetching user', orgname);
    let param = { 'name': orgname };
    return getOrgByName(param).then(res => {
      if (res.data.data.length < 1) {
        this.setState({
          addOrgButtonFlag: true
        });
      } else {
        this.setState({
          addOrgButtonFlag: false
        });
      }
      return res.data.data.map(org => ({
        label: org.orgName,
        value: org.orgId
      }))
    })

  };

  checkAccount = e => {
    let account = e.target.value;
    if (null === account || typeof account === undefined || "undefined" === account || "" === account) return;
    // const param=JSON.stringify({account:account,type:'expertTable'})
    const param = qs.stringify({ 'account': account, 'type': 'expert' });
    judgeAccount(param).then(res => {
      let data = res.data;
      if (data.code === 2008) {
        //账号已存在
        return message.error({
          content: '此身份证号已注册，请核实后再填写！',
          style: {
            marginTop: '40vh'
          }
        })
      }
    })
  };

  // 长江技术经济协会专家须知确认
  checkOne = e => {
    // console.log(e, 111)
    let checkOneValue = e.target.checked;
    this.setState({
      checkOneValue: checkOneValue
    })
  };

  checkForeign = e => {
    this.setState({
      checkForeignValue: e.target.checked
    })
  };

  queryOne = () => {
    let dom = document.getElementsByClassName('expertContent')[0];
    if (this.state.checkOneValue) {
      dom.scrollTop = 0;
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1
      })
    }
  };

  // 专家表格
  // checkTwo = e => {
  //   // console.log(e, 111)
  //   let checkTwoValue = e.target.checked
  //   this.setState({
  //     checkTwoValue: checkTwoValue
  //   })
  // }

  // queryTwo = () => {
  //   if(this.state.checkTwoValue) {
  //     this.setState({
  //       showContentOne: false,
  //       showContentTwo: false,
  //       showContenThree: true,
  //       currentStep: 2
  //     })
  //   }
  // }

  // 是否是院士中选择其他需自己填入
  academicianCheck = e => {
    // console.log(e, 111111)
    this.setState({
      academicianValue: e.target.value
    })
  };

  setOtherAcademician = e => {
    // console.log(e, 111111)
    let data = e.target.value;
    if (!data.trim()) return;
    this.setState({
      otherAcademician: data
    })
  };

  // 生日赋值
  timeChange = (date, dateString) => {
    this.setState({
      birthday: dateString
    })
  };

  // 身份证号发生变化时自动复制出生日期
  onCardIdChange = () => {
    let formCurrent = this.formRef.current;
    formCurrent.setFieldsValue({
      birthday: getBirthdayFromIdCard(formCurrent.getFieldValue('idCard'))
    })
    this.setState({
      birthday: moment(formCurrent.getFieldValue('birthday')).format('YYYY-MM-DD')
    })
  }

  // 评定时间赋值
  zcDateChange = (date, dateString) => {
    this.setState({
      zcDate: dateString
    })
  };

  // 上传照片前，手动上传照片


  // 上传照片
  normFile = e => {
    this.setState({
      uploadImage: e.file
    });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // 专家是否成为会员
  isMember = e => {
    let formCurrent = this.formRef.current;
    let data = e.target.value;
    if (data === 2) {
      formCurrent.setFieldsValue({
        memberTag1: "无"
      })
      this.setState({
        isShowPwd: true
      })
    } else {
      formCurrent.setFieldsValue({
        memberTag1: "会员"
      })
      this.setState({
        isShowPwd: true
      })
    }
  };

  // 职称为其他时需要自己输入
  zcCheck = value => {
    this.setState({
      nowZc: value
    })
  };

  // 提交
  onFinish = e => {
    this.setState({
      loading: true
    }, () => {
      let { birthday, otherAcademician, zcDate } = this.state;
      let allData = e;
      let formData = new FormData();
      allData.birthday = birthday;
      allData.zcDate = zcDate;
      if (allData.sex === 1) {
        allData.sex = '男'
      } else {
        allData.sex = '女'
      }
      if (allData.isAcademician === 1) {
        allData.isAcademician = '中科院院士'
      } else if (allData.isAcademician === 2) {
        allData.isAcademician = '工程院院士'
      } else if (allData.isAcademician === 3) {
        allData.isAcademician = '无'
      } else if (allData.isAcademician === 4 && otherAcademician) {
        allData.isAcademician = otherAcademician
      } else {
        allData.isAcademician = '其他'
      }
      if (allData.isMember === 1) {
        allData.isMember = '是'
      } else {
        allData.isMember = '否'
      }
      if (allData.zc == "其他") {
        allData.zc = allData.otherZc ? allData.otherZc : ""
      }
      formData.append('name', allData.name?.trim());
      formData.append('city', allData.city?.trim());
      formData.append('birthday', allData.birthday);
      formData.append('sex', allData.sex);
      formData.append('national', allData.national?.trim());
      formData.append('idCard', allData.idCard);
      formData.append('image', allData.image[0].originFileObj);
      formData.append('party', allData.party);
      formData.append('zc', allData.zc);
      formData.append('subject', allData.subject);
      formData.append('zcDate', allData.zcDate);
      formData.append('isAcademician', allData.isAcademician);
      formData.append('partTimeJob', allData.partTimeJob?.trim());
      formData.append('tel', allData.tel?.trim());
      formData.append('email', allData.email);
      formData.append('mobileTel', allData.mobileTel?.trim());
      formData.append('fax', allData.fax?.trim());
      formData.append('address', allData.address?.trim());
      formData.append('zipCode', allData.zipCode?.trim());
      formData.append('graduatedFrom', allData.graduatedFrom?.trim());
      formData.append('education', allData.education);
      formData.append('major', allData.major?.trim());
      formData.append('majorNow', allData.majorNow?.trim());
      formData.append('duringMajor', allData.duringMajor?.trim());
      formData.append('zw', allData.zw?.trim());
      formData.append('organization', this.state.orgObj.label);
      formData.append('orgId', this.state.orgObj.value);
      formData.append('workTime', allData.workTime?.trim());
      formData.append('workAddress', allData.workAddress?.trim());
      formData.append('unitNumber', allData.unitNumber?.trim());
      formData.append('achievement', allData.achievement?.trim());
      formData.append('workHistory', allData.workHistory?.trim());
      formData.append('bankAccountName', allData.bankAccountName?.trim());
      formData.append('bankName', allData.bankName?.trim());
      formData.append('bankAccount', allData.bankAccount?.trim());

      formData.append('foreigner', this.state.checkForeignValue);
      if (allData.isMember === '否') {
        formData.append('pwd', '')
      } else {
        formData.append('pwd', allData.pwd)
      }
      formData.append('isMember', allData.isMember);
      formData.append('recommond', allData.recommond);
      formData.append('confirmStatus', '0');
      if (!allData.memberTag1) {
        allData.memberTag1 = '';
      }
      formData.append('memberTag1', allData.memberTag1);
      formData.append('vId', `expert_${new Date().getTime()}${allData.mobileTel}`);
      uploadExpertData(formData).then(res => {
        this.setState({
          loading: false
        });
        let data = res.data;
        if (data.code === 200) {
          message.success('注册成功，请等待审核！');
          this.setState({
            showContentTwo: false,
            showContentThree: true,
            currentStep: 2
          })
        } else if (data.code === 2008) {
          return message.error({
            content: '此身份证号已注册，请核实后再填写！'
          })
        } else {
          return message.error('注册失败，请重新注册！')
        }
      }).catch((error) => {
        this.setState({
          loading: false
        });
        return message.error('注册异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！')
      })
    })
  };

  // 返回登录页
  backLogin = () => {
    this.props.history.push('/login')
  };

  render() {
    return (
      <div className="registerExpert">
        <div className="loginLog">
          <img className="logoImage" src={logo} alt="logo" />
        </div>
        <div className="expertBox">
          <div className="expertStep">
            <Steps current={this.state.currentStep}>
              <Step title="第一步" description="阅读专家须知" />
              <Step title="第二步" description="填写专家注册信息" />
              <Step title="第三步" description="注册温馨提示" />
            </Steps>
          </div>
          <div className="expertContent">
            <div className={`${this.state.showContentOne ? '' : 'hideContent'}`}>
              <Expertnotice />
              <div className="constitutionConfirm">
                <div className="checkBox">
                  <Checkbox checked={this.state.checkOneValue} onChange={e => this.checkOne(e)}>本人已阅读，并了解长江技术经济学会专家须知</Checkbox>
                </div>
                <Button className={`${this.state.checkOneValue ? 'checked' : 'noChecked'} registerBtn`} onClick={this.queryOne}>下一步</Button>
              </div>
            </div>
            <div className={`${this.state.showContentTwo ? '' : 'hideContent'}`}>
              {/* <Unitautonomy /> */}
              <div className="tableConfirm">
                <Form
                  ref={this.formRef}
                  {...formItemLayout}
                  name="register"
                  initialValues={this.state.registerData}
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <div className="baseTitle"><span>个人信息</span></div>
                  <Form.Item
                    name="name"
                    label="姓名"
                    rules={[
                      {
                        required: true,
                        message: '请填写姓名',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{ 'display': 'none' }}
                    name="memberTag1"
                    label="学会职务"
                  >
                    <Select placeholder="请选择学会职务">
                      {memberTagOption.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="foreigner"
                    label="外籍人员"
                  >
                    <Checkbox checked={this.state.checkForeignValue} onChange={e => this.checkForeign(e)}></Checkbox>
                  </Form.Item>
                  {this.state.checkForeignValue ?
                    <Form.Item
                      name="idCard"
                      label="外籍身份证号"
                      onBlur={this.checkAccount.bind(this)}
                      rules={[
                        {
                          required: true,
                          message: '请填写身份证号',
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    :
                    <Form.Item
                      name="idCard"
                      label="身份证号"
                      onBlur={this.checkAccount.bind(this)}
                      rules={[
                        {
                          required: true,
                          message: '请填写身份证号',
                        },
                        // {
                        //   pattern: IDCardReg,
                        //   message: '请填写正确身份证号',
                        // },
                        {
                          validator: checkIdCard
                        }
                      ]}
                    >
                      <Input onChange={this.onCardIdChange} />
                    </Form.Item>
                  }

                  <Form.Item
                    name="city"
                    label="所在省份"
                    rules={[
                      {
                        required: true,
                        message: '请填写所在省市',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input placeholder="省市，例如：湖北省武汉市" />
                  </Form.Item>
                  <Form.Item
                    name="birthday"
                    label="出生年月"
                    rules={[
                      {
                        required: true,
                        message: '请填写出生年月'
                      }
                    ]}
                  >
                    <DatePicker inputReadOnly locale={locale} placeholder="请选择时间" onChange={this.timeChange} />
                  </Form.Item>
                  <Form.Item
                    name="sex"
                    label="性别"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="sex">
                      <Radio value={1}>男</Radio>
                      <Radio value={2}>女</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="national"
                    label="民族"
                    rules={[
                      {
                        required: true,
                        message: '请填写民族',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="image"
                    label="上传照片"
                    valuePropName="fileList"
                    getValueFromEvent={this.normFile}
                    help="照片大小不得超过10MB(严禁在本互联网非涉密平台处理、传输国家秘密。请确认扫描、传输的文件资料不涉及国家秘密)"
                    rules={[
                      {
                        required: true,
                        message: '请上传本人登记照片'
                      }
                    ]}
                  >
                    <Upload name="image" accept=".jpg, .jpeg, .png"
                      maxCount={1}
                      listType="picture"
                      beforeUpload={beforeUpload} >
                      <Button icon={<UploadOutlined />}>请上传本人登记照片</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="party"
                    label="政治面貌"
                    rules={[
                      {
                        required: true,
                        message: '请选择政治面貌',
                      }
                    ]}
                  >
                    <Select placeholder="请选择政治面貌">
                      {politic.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="zc"
                    label="技术职称"
                    rules={[
                      {
                        required: true,
                        message: '请选择技术职称',
                      }
                    ]}
                  >
                    <Select placeholder="请选择技术职称" onChange={this.zcCheck}>
                      {technicalTitle.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {this.state.nowZc == "其他" ?
                    <Form.Item
                      name="otherZc"
                      label="其他职称"
                      rules={[
                        {
                          required: true,
                          message: '请输入其他职称',
                        }
                      ]}
                    >
                      <Input style={{ width: 150 }} placeholder="若选其他，请填写" />
                    </Form.Item>
                    : null
                  }
                  <Form.Item
                    name="zcDate"
                    label="评聘时间"
                    rules={[
                      {
                        required: true,
                        message: '请填写评聘时间(年月即可)',
                      }
                    ]}
                  >
                    <DatePicker inputReadOnly style={{ width: '200px' }} locale={locale} placeholder="请选择评聘时间时间" onChange={this.zcDateChange} />
                  </Form.Item>
                  <Form.Item
                    name="isAcademician"
                    label="是否院士"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="isAcademician" onChange={this.academicianCheck}>
                      <Radio value={1}>中科院院士</Radio>
                      <Radio value={2}>工程院院士</Radio>
                      <Radio value={3}>无</Radio>
                      <Radio value={4}>
                        其他
                        {this.state.academicianValue === 4 ? <Input style={{ width: 150, marginLeft: 10 }} placeholder="若选其他，请填写" onChange={this.setOtherAcademician} /> : null}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="partTimeJob"
                    label="社会兼职"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="bankAccount"
                    label="银行账号"
                    help="用于发放咨询费用"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="bankAccountName"
                    label="开户名"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="bankName"
                    label="开户行"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <div className="baseTitle"><span>联系方式</span></div>
                  <Form.Item
                    name="tel"
                    label="座机"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="电子邮箱"
                    rules={[
                      {
                        type: 'email',
                        message: '输入不是有效电子邮箱',
                      },
                      {
                        required: true,
                        message: '请填写电子邮箱',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="mobileTel"
                    label="手机"
                    rules={[
                      {
                        required: true,
                        message: '请填写手机联系电话',
                      },
                      {
                        pattern: phoneReg,
                        message: '请填写正确手机联系方式'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="fax"
                    label="传真"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="通讯地址"
                    rules={[
                      {
                        required: true,
                        message: '请填写通讯地址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="zipCode"
                    label="邮编"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="baseTitle"><span>个人经历</span></div>
                  <Form.Item
                    name="graduatedFrom"
                    label="毕业院校"
                    rules={[
                      {
                        required: true,
                        message: '请填写毕业院校',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="education"
                    label="最高学历"
                    rules={[
                      {
                        required: true,
                        message: '请填写最高学历',
                      }
                    ]}
                  >
                    <Select placeholder="请选择最高学历">
                      {qualification.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="major"
                    label="专业"
                    rules={[
                      {
                        required: true,
                        message: '请填写专业',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="majorNow"
                    label="现在从事专业"
                    rules={[
                      {
                        required: true,
                        message: '请填写现在从事专业',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="subject"
                    label="学科领域"
                    rules={[
                      {
                        required: true,
                        message: '请选择学科领域',
                      }
                    ]}
                  >
                    <Select placeholder="请选择学科领域">
                      {subjectOption.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="duringMajor"
                    label="专业工作年限"
                    rules={[
                      {
                        required: true,
                        message: '请填写专业工作年限',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input placeholder="例：5年" />
                  </Form.Item>
                  <Form.Item
                    name="zw"
                    label="行政职务"
                    rules={[
                      {
                        required: true,
                        message: '请填写行政职务',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="organization"
                    label="工作单位"
                    help="请先输入单位名称搜索选择对应单位，如未出现可新增单位"
                    rules={[
                      {
                        required: true,
                        message: '请填写工作单位',
                      }
                    ]}
                  >
                    {/*<Input />*/}
                    <DebounceSelect
                      value={this.state.orgObj}
                      placeholder="搜索单位"
                      fetchOptions={this.fetchOrgList}
                      onChange={this.handleOrgChange}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                  {this.state.addOrgButtonFlag ?
                    <Form.Item name="orgNameAdd" label=" ">
                      没有找到对应单位？试试<Button onClick={this.showAddOrgModal}>新增单位</Button>
                    </Form.Item> : ""
                  }

                  <Modal title="新增单位" visible={this.state.showAddOrgModalFlag}
                    onOk={this.handleAddOrgOk}
                    onCancel={this.handleAddOrgCancel}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Form.Item
                      className='unitName'
                      name="unitName"
                      rules={[
                        {
                          pattern: SpaceReg,
                          message: '输入内容不能为空'
                        }
                      ]}
                    >
                      <Input placeholder="输入单位名称" value={this.state.addOrgName} onChange={this.onOrgNameChange} />
                    </Form.Item>
                  </Modal>
                  {/* <Form.Item
                    name="workTime"
                    label="工作时间"
                    rules={[
                      {
                        required: true,
                        message: '请填写工作时间',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input placeholder="例：5年" />
                  </Form.Item> */}
                  <Form.Item
                    name="workAddress"
                    label="单位地址"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位地址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="unitNumber"
                    label="单位电话"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="workHistory"
                    label="主要工作经历"
                    rules={[
                      {
                        required: true,
                        message: '请填写主要工作经历',
                      },
                      {
                        type: 'string',
                        max: 1000,
                        message: '字数不能超过一千字',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input.TextArea rows={10} />
                  </Form.Item>
                  <Form.Item
                    name="achievement"
                    label="主要学术成就"
                    rules={[
                      {
                        required: true,
                        message: '请填写主要学术成就'
                      },
                      // {
                      //   type: 'string',
                      //   max: 1000,
                      //   message: '字数不能超过一千字',
                      // },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input.TextArea rows={10} showCount maxLength={1000} placeholder="代表性成果、担任评委经历等，1000字以内" />
                  </Form.Item>
                  <div className="baseTitle"><span>其他</span></div>
                  <Form.Item
                    name="isMember"
                    label="是否同时成为会员"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="isMember" onChange={this.isMember}>
                      <Radio value={1}>是</Radio>
                      <Radio value={2}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="recommond"
                    label="是否分支机构推荐"
                    rules={[
                      {
                        required: true,
                        message: '请选择是否分支机构推荐',
                      }
                    ]}
                  >
                    <Select placeholder="请选择是否分支机构推荐">
                      {isRecommend.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {this.state.isShowPwd ? <div><Form.Item
                    name="pwd"
                    label="登录密码"
                    rules={[
                      {
                        validator: checkPassword
                      },
                      {
                        required: true,
                        message: '请填写登录密码!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                    <Form.Item
                      name="confirm"
                      label="确认密码"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: '请确认您的密码!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('pwd') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('两次密码不一致!');
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="promiseWord"
                        label="入会声明"
                    >
                      <Input.TextArea disabled rows={1}/>
                    </Form.Item></div> : true}
                  <div className="buttonItem">
                    {this.state.isUpdate ? <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      更改提交
                    </Button> : <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      提交
                    </Button>}
                  </div>
                </Form>
              </div>
            </div>
            <div className={`${this.state.showContentThree ? '' : 'hideContent'}`}>
              <Registerwait />
              <div className="contentThreeButton">
                <Button className='registerBtn backBtn' type="primary" onClick={this.backLogin}>返回登录页</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">©2021 长江技术经济学会 版权所有</span>
        </div>
      </div>
    )
  }
}

export default Registerexpert
